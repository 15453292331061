import React from 'react';
import ReactDOM from 'react-dom';
import {
  useParams,
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import './index.css';
import App from './App';
import MakeAWish from './Pages/MakeAWish'
import Links from './Pages/Links'
import * as serviceWorker from './serviceWorker';
import awsconfig from './aws-exports'

import Amplify from "aws-amplify";
import awsExports from "./aws-exports";

// Cookies
import { CookiesProvider } from 'react-cookie';

// Bug tracking
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
  apiKey: 'c2c662c1152796bf0b5c413936b014c7',
  plugins: [new BugsnagPluginReact()],
  sourceMapEnabled: true,
})
BugsnagPerformance.start({ apiKey: 'c2c662c1152796bf0b5c413936b014c7' })

Amplify.configure(awsExports);

global.Buffer = global.Buffer || require('buffer').Buffer


const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

// Get backend environment
const envStart = awsconfig.aws_cloud_logic_custom[0].endpoint.lastIndexOf('/');
const env = awsconfig.aws_cloud_logic_custom[0].endpoint.slice(envStart+1);

function ExternalRedirect({ to, urlValue }) {
  const redirectUrl = urlValue ? `${to}${urlValue}` : to;
  window.location.replace(redirectUrl);
  return null;
}

function WishwellRedirect() {
  const { urlValue } = useParams();
  console.log(urlValue);
  // Switch statement for values of urlValue using the below Route paths with the default value being the one below:
    switch (urlValue) {
      case 'growth':
        return <ExternalRedirect to="https://app.hubspot.com/reports-dashboard/24356242/view/10390241" />
      case 'dev': 
        return <ExternalRedirect to="https://astrophe.atlassian.net/jira/software/projects/WW/boards/3/backlog" />
      case 'brain':
        return <ExternalRedirect to="https://app.asana.com/0/1185382136635034/list" />
      case 'prospects':
        return <ExternalRedirect to="https://app.apollo.io/#/control-center" />
      case 'social':
        return <ExternalRedirect to="https://hootsuite.com/dashboard#/home" />
      case 'recordscreen':
        return <ExternalRedirect to="https://app.screendesk.io/recordings/new?ak=s3wFCw&key=V894ew&src=rria" />;
      case 'remote':
        return <ExternalRedirect to="https://assist.zoho.com/org/805216776/app/home" />;
      case 'remotesupport':
        return <ExternalRedirect to="https://join.zoho.com" />;
      case 'roadmap':
        return <ExternalRedirect to="https://onegift.convas.io/" />;
      case 'papcase':
        return <ExternalRedirect to="https://www.canva.com/design/DAFJAtKVEFw/Upukx3qz0aSHbtxbfswGYA/view" />;
      case 'demo':
        return <ExternalRedirect to="https://www.youtube.com/watch?v=ODDwlFQxTik&t=2s" />; // TODO: Need to update with new demo
      case 'scheduledemo':
        return <ExternalRedirect to="https://onegiftfoundation.org/demo" />;
      case 'kickoff':
        return <ExternalRedirect to="https://onegiftfoundation.org/kickoff" />;
      case 'getstarted':
        return <ExternalRedirect to="https://onegiftfoundation.org/resources/launchwishwell" />;
      case 'howitworks':
        return <ExternalRedirect to="https://onegiftfoundation.org/resources/howitworks" />;
      case 'whitepaper':
        return <ExternalRedirect to="https://onegiftfoundation.org/resources/wishwellwhitepaper" />;
      case 'whywishwell':
        return <ExternalRedirect to="https://youtu.be/aPLFG5Z3_hY" />;
      case 'setuptutorial':
        return <ExternalRedirect to="https://youtu.be/gpRKeglmJsw" />;
      case 'wishestutorial':
        return <ExternalRedirect to="https://youtu.be/NUPclOqdOMI" />;
      case 'why':
        return <ExternalRedirect to="https://youtu.be/1iD1VKeWfDo" />; // TODO: Need to update with donor video
      case 'videos':
        return <ExternalRedirect to="https://www.youtube.com/@onegift" />;
      case 'metrics':
        return <ExternalRedirect to="https://mixpanel.com/s/U6Jh6" />;
      case 'bugs': 
        return <ExternalRedirect to="https://app.bugsnag.com/onegift/wishwell/errors?filters[error.status]=open&filters[event.since]=30d&filters[app.release_stage]=production&sort=last_seen" />;
      default: 
        if (env === 'dev') {
          return <ExternalRedirect to="https://www.dev.wishwell.onegift.ai/makeawish?org=" urlValue={urlValue} />;
        }
        else {
          return <ExternalRedirect to="https://www.wishwell.onegift.ai/makeawish?org=" urlValue={urlValue} />;
        }
    }
}

function SourceRedirect() {
  const { urlValue } = useParams();
  return <ExternalRedirect to={`https://www.wishwell.onegift.ai/?source=${urlValue}&signup=true`} />
}

function AdvocatesRedirect() {
  const { urlValue } = useParams();
  return <ExternalRedirect to={`https://www.wishwell.onegift.ai/?source=advocates&referrer=${urlValue}&signup=true`} />
}

function PartnersRedirect() {
  const { urlValue } = useParams();
  return <ExternalRedirect to={`https://www.wishwell.onegift.ai/?source=partners&referrer=${urlValue}&signup=true`} />
}

function OneGiftRedirect() {
  return <ExternalRedirect to={`https://www.onegiftfoundation.org/wishwell`} />
}

function WishWellSignup() {
  return <ExternalRedirect to={`https://www.wishwell.onegift.ai?signup=true`} />
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <CookiesProvider> 
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/wishwell" element={<OneGiftRedirect />} />
            <Route path="/wishwell/signup" element={<WishWellSignup />} />
            <Route path="/wishwell/links" element={<Links />} />
            <Route path="/wishwell/sops" element={<Links />} />
            <Route path="/wishwell/source/:urlValue" element={<SourceRedirect />} />
            <Route path="/wishwell/advocates/:urlValue" element={<AdvocatesRedirect />} />
            <Route path="/wishwell/partners/:urlValue" element={<PartnersRedirect />} />
            <Route path="/wishwell/:urlValue" element={<WishwellRedirect />} />
            <Route path="/makeawish" element={<MakeAWish />} />
            <Route path="/:urlValue" element={<WishwellRedirect />} />
          </Routes>
        </BrowserRouter>
      </CookiesProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
