import React from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import awsconfig from '../aws-exports'
import { membersByOrganizationId, userByUserName, getOrganization, transactionsByOrganizationId, settingsByOrganizationId } from '../graphql/queries'
import { generateDepositFormData } from '../Functions/DepositForm';
import { generateOrderFormData } from '../Functions/OrderReport';

// CSV export
import { CSVLink } from "react-csv";

// UI
import { Button, Select, Tooltip, Position, Pane, Spinner } from 'evergreen-ui'

// Bug tracking
import Bugsnag from '@bugsnag/js'

// Analytics
import mixpanel from 'mixpanel-browser';

class Reports extends React.Component {
    constructor() {
        super()
        this.state = {
            activeCount: [0,0,0,0,0,0,0],
            p3TypeMembers: [],
            p4TypeMembers: [],
            overdueAngels: [],
            membersForCSV: [],
            depositLinkLoading: false,
            orderLinkLoading: false,
            reportCSVLink: null,
            reportOrderCSVLink: null,
            isLoading: true,
            organization: {
                id: "",
                name: "",
                active: true,
                parentOrgId: "",
                independent: false,
            }
        }
    }

    loadData = async () => {
        // Tracking
        mixpanel.track('Reports page visited');
        try {
            const user_info = await Auth.currentUserInfo()
            const username = user_info.username
            const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
            const orgId = user.data.userByUserName.items[0].organizationId
            let filter = { organizationId: {eq: orgId} };

            // Check for existing setting record
            const organization_return = await API.graphql(graphqlOperation(getOrganization, { id: orgId }))
            this.setState({
                organization: organization_return.data.getOrganization
            })

            var nextToken = null;
            var count = 0
            var filteredList
            var MEMBERS = []

            // Get all PAP Members
            // const PAPIds = [
            //   "3e79a760-b100-4ff7-b9dd-ec3c29df39fd",
            //   "db3cf7f5-e68b-44e3-a1d5-3256865e29dc",
            //   "d918754a-704f-41c5-81ad-9435f3765dbd",
            //   "325de286-b1c1-4367-8f92-d0b0f8a2682a",
            //   "be3b5552-9d75-4d3b-ac24-78a581921f3c",
            //   "5ce84982-2e22-4706-ac0c-8c4f5771fa71",
            //   "1b9b8d20-3015-4b29-90ec-41a389284e44",
            //   "f8028f23-cae9-4424-b976-b7c0353808c3",
            //   "b2d074b9-e817-43e3-a9c2-acc8a293daee",
            //   "e99b0351-3d50-4c91-b90c-23bdd11f75ce",
            //   "00111220-0b38-4696-b6ce-fd9c7bc57888",
            //   "2495f8c1-8b86-435d-8be7-d9996b0e19c1",
            //   "5dee0e62-3d90-4dd6-8dfe-e4e0d08d86eb",
            //   "1615e617-6749-4b06-ab12-e480fb8d5a7c",
            //   "d2e8b63c-5519-4684-9820-07dd3a03f05g",
            //   "f8322cf8-5c9a-4e29-bf1e-da298b1a303d",
            //   "088d0230-6b6e-4704-a49f-0e7ec5fb878d",
            //   "d2e8b63c-5519-4684-9820-07dd3a03f05f",
            //   "90ee45ad-b3bd-4ab0-a3d7-711e754247ae"
            // ]

            // for (const orgId of PAPIds) {
            //   let nextToken = null;
            //   let count = 0;
          
            //   while (nextToken || count === 0) {
            //       count = 1;
            //       const filteredList = await API.graphql(
            //           graphqlOperation(membersByOrganizationId, {
            //               organizationId: orgId,
            //               limit: 900,
            //               nextToken: nextToken
            //           })
            //       );
            //       nextToken = filteredList.data.membersByOrganizationId.nextToken;
            //       MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items);
            //   }
            // }
            
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(membersByOrganizationId, {
                    organizationId: orgId,
                    limit: 900,
                    nextToken:nextToken
                }))
                nextToken = filteredList.data.membersByOrganizationId.nextToken
                MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items)
            }
            MEMBERS.sort(function(a, b) {
                var textA = a.lname.toUpperCase();
                var textB = b.lname.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              });

            // Get transactions
            nextToken = null;
            count = 0
            var TRANSACTIONS = []
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(transactionsByOrganizationId, {
                    organizationId: orgId,
                    limit: 900,
                    nextToken:nextToken
                }))
                nextToken = filteredList.data.transactionsByOrganizationId.nextToken
                TRANSACTIONS = TRANSACTIONS.concat(filteredList.data.transactionsByOrganizationId.items)
            }

            // Get total donations
            var lifetimeDonations = 0
            var yearToDateDonations = 0
            for (var i = 0; i < TRANSACTIONS.length; i++) {
                if (TRANSACTIONS[i].transactionDate.includes(new Date().getFullYear())) {
                    yearToDateDonations += parseFloat(TRANSACTIONS[i].localAmount || TRANSACTIONS[i].amount)
                }
                lifetimeDonations += parseFloat(TRANSACTIONS[i].localAmount || TRANSACTIONS[i].amount)
            }

            const stripeCountryInfo = [
                {
                  country: 'Australia',
                  code: 'AU',
                  currency: 'AUD',
                  symbol: '$'
                },
                {
                  country: 'Austria',
                  code: 'AT',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Belgium',
                  code: 'BE',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Bulgaria',
                  code: 'BG',
                  currency: 'BGN',
                  symbol: 'лв'
                },
                {
                  country: 'Canada',
                  code: 'CA',
                  currency: 'CAD',
                  symbol: '$'
                },
                {
                  country: 'Croatia',
                  code: 'HR',
                  currency: 'HRK',
                  symbol: 'kn'
                },
                {
                  country: 'Cyprus',
                  code: 'CY',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Czech Republic',
                  code: 'CZ',
                  currency: 'CZK',
                  symbol: 'Kč'
                },
                {
                  country: 'Denmark',
                  code: 'DK',
                  currency: 'DKK',
                  symbol: 'kr'
                },
                {
                  country: 'Estonia',
                  code: 'EE',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Finland',
                  code: 'FI',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'France',
                  code: 'FR',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Germany',
                  code: 'DE',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Gibraltar',
                  code: 'GI',
                  currency: 'GBP',
                  symbol: '£'
                },
                {
                  country: 'Greece',
                  code: 'GR',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Hong Kong',
                  code: 'HK',
                  currency: 'HKD',
                  symbol: 'HK$'
                },
                {
                  country: 'Hungary',
                  code: 'HU',
                  currency: 'HUF',
                  symbol: 'Ft'
                },
                {
                  country: 'Ireland',
                  code: 'IE',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Italy',
                  code: 'IT',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Japan',
                  code: 'JP',
                  currency: 'JPY',
                  symbol: '¥'
                },
                {
                  country: 'Latvia',
                  code: 'LV',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Liechtenstein',
                  code: 'LI',
                  currency: 'CHF',
                  symbol: 'CHF'
                },
                {
                  country: 'Lithuania',
                  code: 'LT',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Luxembourg',
                  code: 'LU',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Malta',
                  code: 'MT',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Mexico',
                  code: 'MX',
                  currency: 'MXN',
                  symbol: '$'
                },
                {
                  country: 'Netherlands',
                  code: 'NL',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'New Zealand',
                  code: 'NZ',
                  currency: 'NZD',
                  symbol: '$'
                },
                {
                  country: 'Norway',
                  code: 'NO',
                  currency: 'NOK',
                  symbol: 'kr'
                },
                {
                  country: 'Poland',
                  code: 'PL',
                  currency: 'PLN',
                  symbol: 'zł'
                },
                {
                  country: 'Portugal',
                  code: 'PT',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Romania',
                  code: 'RO',
                  currency: 'RON',
                  symbol: 'lei'
                },
                {
                  country: 'Singapore',
                  code: 'SG',
                  currency: 'SGD',
                  symbol: '$'
                },
                {
                  country: 'Slovakia',
                  code: 'SK',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Slovenia',
                  code: 'SI',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Spain',
                  code: 'ES',
                  currency: 'EUR',
                  symbol: '€'
                },
                {
                  country: 'Sweden',
                  code: 'SE',
                  currency: 'SEK',
                  symbol: 'kr'
                },
                {
                  country: 'Switzerland',
                  code: 'CH',
                  currency: 'CHF',
                  symbol: 'CHF'
                },
                {
                  country: 'Thailand',
                  code: 'TH',
                  currency: 'THB',
                  symbol: '฿'
                },
                {
                  country: 'United Arab Emirates',
                  code: 'AE',
                  currency: 'AED',
                  symbol: 'د.إ'
                },
                {
                  country: 'United Kingdom',
                  code: 'GB',
                  currency: 'GBP',
                  symbol: '£'
                },
                {
                  country: 'United States',
                  code: 'US',
                  currency: 'USD',
                  symbol: '$'
                }
            ];
            const settings_record = await API.graphql(graphqlOperation(settingsByOrganizationId, { organizationId: orgId }))
            const countryMap = settings_record.data.settingsByOrganizationId.items[0].country
            const country = JSON.parse(`{${countryMap.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`)
            const countryInfo = stripeCountryInfo.find(info => info.country === country.value);
            const countryCurrency = countryInfo ? countryInfo.currency : 'usd';

            const currencySymbol = new Intl.NumberFormat('en-US', { style: 'currency', currency: countryCurrency }).formatToParts()[0].value;

            this.setState({
                lifetimeDonations: lifetimeDonations,
                yearToDateDonations: yearToDateDonations,
                currencySymbol: currencySymbol
            })

        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }
        this.state.membersForCSV.push(["P Number", "M Number", "P Type", "Active", "First Name", "Last Name", "Email", "Phone", "Birthday or Anniversary", "Address", "Address 2", "Angel Number", "Angel Payment", "Notes", "E Forms", "P Forms", "E Cards", "P Cards"])
        for (i = 0; i < MEMBERS.length; i++) {
            if (MEMBERS[i].pactive) {
                if (MEMBERS[i].ptype) {
                    this.state.activeCount[MEMBERS[i].ptype]++
                }
                else {
                    this.state.activeCount[0]++
                }
            }

            this.state.membersForCSV.push([MEMBERS[i].pnumber, MEMBERS[i].mnumber, MEMBERS[i].ptype, MEMBERS[i].pactive, MEMBERS[i].fname, MEMBERS[i].lname, MEMBERS[i].email, MEMBERS[i].phone, MEMBERS[i].DOB, MEMBERS[i].address1, MEMBERS[i].address2, MEMBERS[i].anumber, MEMBERS[i].apayment, MEMBERS[i].notes, MEMBERS[i].eforms, MEMBERS[i].pforms, MEMBERS[i].ecards, MEMBERS[i].pcards])
            var utc = new Date(MEMBERS[i].apayment);
            var date = new Date()
            if (MEMBERS[i].anumber !== 0 && ((utc === null) || ((date - utc) > 31556926000))) {
                this.state.overdueAngels.push(
                    <MemberList
                        pNumber={MEMBERS[i].pnumber}
                        fName={MEMBERS[i].fname}
                        lName={MEMBERS[i].lname}
                    />
                )
            }
            if (MEMBERS[i].ptype === 3) { 
                this.state.p3TypeMembers.push(
                    <MemberList
                        pNumber={MEMBERS[i].pnumber}
                        fName={MEMBERS[i].fname}
                        lName={MEMBERS[i].lname}
                    />
                )
            }
            if (MEMBERS[i].ptype === 4) { 
                this.state.p4TypeMembers.push(
                    <MemberList
                        pNumber={MEMBERS[i].pnumber}
                        fName={MEMBERS[i].fname}
                        lName={MEMBERS[i].lname}
                    />
                )
            }
        }
        this.setState({
            isLoading: false        
        })
    }

    componentDidMount(){
        this.loadData()
    }

    generateDepositFormLink = async () => {
        const yearmonth = document.getElementById("year").value + "-" + document.getElementById("month").value
        const data = await generateDepositFormData(yearmonth)
        if (!data) {
            this.setState({
                depositLinkLoading: false
            })
            return
        }
        // Tracking
        mixpanel.track('Deposit report generated');
        this.setState({
            reportCSVLink: <CSVLink data={data} filename={"Deposit report - " + yearmonth + ".csv"}>Download deposit report for {yearmonth}</CSVLink>,
            depositLinkLoading: false
        })
    }

    generateOrderFormLink = async () => {
        const yearmonth = document.getElementById("orderreportyear").value + "-" + document.getElementById("orderreportmonth").value
        const data = await generateOrderFormData(yearmonth)
        // Tracking
        mixpanel.track('Orders report generated');
        this.setState({
            reportOrderCSVLink: <CSVLink data={data} filename={"Order report - " + yearmonth + ".csv"}>Download order report for {yearmonth}</CSVLink>,
            orderLinkLoading: false
        })
    }
    render() {
        if (this.state.isLoading) {
            // if (false) {
            return(
                <div>
                    <Pane display="flex" alignItems="center" justifyContent="center" height={400}><Spinner /></Pane>
                </div>
            )
        }
        else {
            return (
                <div>
                    <h1>Quick Stats</h1> 
                    <table  className="responsive-table"><tbody>
                        <tr>
                            <td><h3>Active Members:</h3></td>
                            <td style={{textAlign: 'left'}}>{this.state.activeCount.reduce((a, b) => a + b, 0) - (this.state.activeCount[5] ? this.state.activeCount[5] : 0)}</td>
                        </tr>
                        <tr>
                        <td><h3>Donations (year to date):</h3></td>
                        <td style={{textAlign: 'left'}}>{this.state.currencySymbol}{this.state.yearToDateDonations ? this.state.yearToDateDonations.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : null}</td>
                        </tr>
                        <tr>
                        <td><h3>Donations (lifetime):</h3></td>
                        <td style={{textAlign: 'left'}}>{this.state.currencySymbol}{this.state.lifetimeDonations ? this.state.lifetimeDonations.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : null}</td>
                        </tr>
                    </tbody></table>
                    <br></br>
                    <br></br>
                    <div><CSVLink 
                        data={this.state.membersForCSV} 
                        filename={"WishWell Member Export.csv"}
                        onClick={() => {
                            mixpanel.track('Members exported');
                        }}
                    >Export Member List</CSVLink></div>
                    <br></br>
                    {/* DEPOSIT REPORT */}
                    <h2>Deposit Report</h2>
                    <Select name="month" id="month">
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </Select>
                    <Select name="year" id="year">
                        <option value="2022">2022</option>
                        <option selected value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                    </Select>
                    <br></br>
                    <br></br>
                    <Tooltip content="Date range uses check dates." position={Position.RIGHT}>
                        <Button isLoading={this.state.depositLinkLoading} type="submit" onClick={() => { this.generateDepositFormLink(); this.setState({ depositLinkLoading: true });}}>Generate Deposit Report</Button>
                    </Tooltip>
                    <br></br>
                    <br></br>
                    {this.state.reportCSVLink}

                    {/* ORDERS REPORT */}
                    <h2>Orders Report</h2>
                    {/* TODO: use current year to polpulate list of years */}
                    <Select name="orderreportmonth" id="orderreportmonth">
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </Select>
                    <Select name="orderreportyear" id="orderreportyear">
                        <option value="2022">2022</option>
                        <option selected value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                    </Select>
                    <br></br>
                    <br></br>
                    <Tooltip content="Date range based on when order was entered into system" position={Position.RIGHT}>
                        <Button isLoading={this.state.orderLinkLoading} type="submit" onClick={() => { this.generateOrderFormLink(); this.setState({ orderLinkLoading: true });}}>Generate Order Report</Button>
                    </Tooltip>
                    <br></br>
                    <br></br>
                    {this.state.reportOrderCSVLink}
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    {/* TODO: remove hard-coded pap custom field filter */}
                {this.state.organization.parentOrgId === '859d0bb4-21c4-4c0d-9804-126093d78552' && <div><h3>Active Members By Type</h3>
                    P1: {this.state.activeCount[1]} <br></br>
                    P2: {this.state.activeCount[2]} <br></br>
                    P3: {this.state.activeCount[3]} <br></br>
                    P4: {this.state.activeCount[4]} <br></br>
                    P5: {this.state.activeCount[5]} <br></br>
                    P6: {this.state.activeCount[6]}
                    <table style={{ alignItems: "top", textAlign: "left", display: "flex", justifyContent: "center", borderSpacing: '30px' }}><tbody>
                        <tr>
                            <td>
                                PAP Type 3
                            </td>
                            <td>
                                PAP Type 4
                            </td>
                            <td>
                                Unpaid Angels
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "top" }}>
                                {this.state.p3TypeMembers}
                            </td>
                            <td style={{ verticalAlign: "top" }}>
                                {this.state.p4TypeMembers}
                            </td>
                            <td style={{ verticalAlign: "top" }}>
                                {this.state.overdueAngels}
                            </td>
                        </tr>
                    </tbody></table></div>}
                    <br></br>
                    <br></br>
                </div>
            )
        }
    }
}

const MemberList = (props) => {
    return(
      <div>
          {props.pNumber} - {props.fName} {props.lName}
      </div>
    )
}

export default Reports