/* src/App.js */
import React, { Component } from 'react'
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'
import { membersByOrganizationId, ordersByOrganizationId, userByUserName, settingsByOrganizationId, listSettings, getOrganization } from '../graphql/queries'
import { updateMember } from '../graphql/mutations'
//import generatelabels from '../../amplify/backend/function/generatelabels/src/event.json'
// import { generateDepositFormData } from '../Functions/DepositForm'
// import docx and fs libraries to generate docx files
// import * as fs from "fs";
import { AlignmentType, BorderStyle, Document, TextRun, HeightRule, Media, Packer, Paragraph, PageOrientation, Table, TableCell, TableRow, WidthType, VerticalAlign, VerticalPositionAlign } from "docx";
import FileSaver from "file-saver"
import JSZip from "jszip"

// UI
import { Select, Button, Menu, Popover, Position, FollowerIcon, Pane, Dialog, IconButton, Checkbox, Autocomplete, AlignJustifyIcon, TextInput, PersonIcon, CogIcon, CreditCardIcon, AddIcon, HorizontalBarChartIcon, FormIcon, InboxIcon, LogOutIcon, CloudDownloadIcon, Spinner, toaster, Tooltip, HelpIcon } from 'evergreen-ui'
import '../Styles/navbar.css'
import wishWellLogo from './Images/WishWell.png'
import '../Styles/progressbar.css'
import logo from '../wishwellbyonegift.svg'

// Cookies, Notification, and NPS
import Notification from '../Functions/Notifications'
import NPS from '../Functions/NPS';

// Bug tracking
import Bugsnag from '@bugsnag/js'

// Tutorials
// import introJs from 'intro.js';
// import 'intro.js/introjs.css';
// import { IntroJs } from 'react-introjs';

// Extensions
import MemberDetails from './MemberDetails'
import OrderForm from './OrderForm'
import Reports from './Reports'
import Settings from './Settings'
import { orderMailerImageBase64, birthdayImageBase64, anniversaryImageBase64, birthdayBackgroundBase64, anniversaryBackgroundBase64, birthdayCardBackgroundBase64, anniversaryCardBackgroundBase64 } from './Images/docx-images-base64'


// Analytics
import mixpanel from 'mixpanel-browser';

// Docx to pdf converter api
import ConvertApi from 'convertapi-js'
import axios from 'axios';
let convertApi = ConvertApi.auth(process.env.REACT_APP_CONVERTAPITOKEN)

class Main extends Component {
  constructor() {
    super()
    this.state = {
      Activity: "Tools",
      member: {
        id: null,
        pnumber: null,
        mnumber: null,
        ptype: "",
        pactive: null,
        eforms: null,
        pforms: null,
        ecards: null,
        pcards: null,
        birthdayBooster: null,
        eReminders: null,
        fname: "",
        lname: "",
        email: "",
        phone: null,
        address: "",
        addressRemote: "",
        DOB: "",
        anumber: null,
        apayment: "",
        notes: "",
        searchField: ""
      },
      organization: {
          id: '',
          name: '',
          parentOrgId: '',
          active: true,
          independent: false,
      },
      allMembers: [],
      memberSelected: "",
      year: new Date().getFullYear(),
      emailOrderForms: false,
      generateModalShown: false,
      generateLoading: false,
      generateOrderFormsModalShown: false, 
      generateOrderFormsLoading: false,
      memberDetailsLoading: false,
      cAndEType: 'OneFile',
      emailCards: false,
      state: "",
      loading: true,
      userPermissions: [],
      setupComplete: true,
    }
    this.changeState = this.changeState.bind(this)
    this.DisplayMember = this.DisplayMember.bind(this)
    this.getSettings = this.getSettings.bind(this)
    this.GenerateLabels = this.GenerateLabels.bind(this)
    this.GenerateOrderForms = this.GenerateOrderForms.bind(this)
    this.GenerateCandE = this.GenerateCandE.bind(this)
    this.GenerateManyCandE = this.GenerateManyCandE.bind(this)
    this.GenerateIndividualCandE = this.GenerateIndividualCandE.bind(this)
    this.loadPage = this.loadPage.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.emailOrderForms = this.emailOrderForms.bind(this)
  }

  // Signout function
  async signOut() {
    try {
        await Auth.signOut();
        return false;
    } catch (error) {
        Bugsnag.notify(error);
        console.log('error signing out: ', error);
    }
  }

  componentDidMount() {
    this.loadPage()
  }

  loadPage = async () => {
      this.setState({
          memberDetailsLoading: true
      })
      // Retrieve members
      var userPermissions = []
      try {
        var nextToken = null;
        var count = 0
        var filteredList
        var MEMBERS = []
        const user_info = await Auth.currentUserInfo()
        if (!user_info || !user_info.username) {
          // insert 5 second pause
          await new Promise(resolve => setTimeout(resolve, 5000));
          this.loadPage()
          return
        }
        const username = user_info.username
        const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
        if (user && user.data && user.data.userByUserName && user.data.userByUserName.items && user.data.userByUserName.items[0]) {
          const orgId = user.data.userByUserName.items[0].organizationId
          var settings = await this.getSettings(user)
          if (!settings) {
            this.loadPage()
          }
          else {

            // Check for existing setting record
            const organization_return = await API.graphql(graphqlOperation(getOrganization, { id: orgId }))
            this.setState({
                organization: organization_return.data.getOrganization
            })

            const permissions = user.data.userByUserName.items[0].permissions
            const parsedPermissions = permissions.map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`))
            userPermissions = parsedPermissions.map((permission) => permission.label)

            // Tracking
            mixpanel.identify(username)
            Bugsnag.setUser(username)
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(membersByOrganizationId, {
                  organizationId: orgId,
                  // organizationId: 'f8028f23-cae9-4424-b976-b7c0353808c3',
                  limit: 900,
                  nextToken:nextToken}))
                nextToken = filteredList.data.membersByOrganizationId.nextToken
                MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items)
            }
            MEMBERS.sort(function(a, b) {
              var textA = a.lname.toUpperCase();
              var textB = b.lname.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            if (!settings.logo || settings.logo[0] === "") {
              var logo = [
                "WishWell by OneGift",
                wishWellLogo
              ]
              settings.logo = logo
            }
            else {
              const logo = await Storage.get(settings.logo[0])
              settings.logo[1] = logo
            }

            if (settings.bankInfo[0] === "") {
              this.setState({ setupComplete: false })
            }

            if (settings !== "") {
              this.setState({
                settings: settings,
                loading: false
              })
            }

            // SCRIPT: to update members
            // for (var i = 0; i < MEMBERS.length; i++) {
            //   // if (MEMBERS[i].ptype === 5 || MEMBERS[i].ptype === 4) {
            //     var updatedMember = {}
            //     updatedMember.id = MEMBERS[i].id
            //     updatedMember.ecards = true
            //     updatedMember.pcards = true
            //     updatedMember.pforms = true
            //     updatedMember.eforms = true
            //     // updatedMember.birthdayBooster = false
            //     // updatedMember.eReminders = true
            //     await API.graphql(graphqlOperation(updateMember, {input: updatedMember}))
            //         .catch((err) => console.log(err))
            //     console.log('1 member updated')
            //   // }
            // }
            // console.log('all members updated')

            // SCRIPT: to update settings
            // for (var i = 0; i < settings.length; i++) {
            //   // if (MEMBERS[i].ptype === 5 || MEMBERS[i].ptype === 4) {
            //     var updatedSetting = {}
            //     updatedSetting.id = settings[i].id
            //     updatedSetting.birthdayBooster = false
            //     await API.graphql(graphqlOperation(updateSetting, {input: updatedSetting}))
            //         .catch((err) => console.log(err))
            //     console.log('1 setting updated')
            //   // }
            // }
            // console.log('all settings updated')

            // SCRIPT: to update transactions
            // var nextToken = null;
            // var count = 0
            // var filteredList
            // var TRANSACTIONS = []
            // filter = {
            //     and: [{ organizationName: { eq: 'Aberdeen' } },]
            // };
            // while (nextToken || count === 0) {
            //     count = 1
            //     filteredList = await API.graphql(
            //     graphqlOperation(listTransactions, {
            //         filter: filter,
            //         limit: 900,
            //         nextToken:nextToken
            //     }))
            //     nextToken = filteredList.data.listTransactions.nextToken
            //     TRANSACTIONS = TRANSACTIONS.concat(filteredList.data.listTransactions.items)
            // }

            // for (var i = 0; i < TRANSACTIONS.length; i++) {
            //   var updatedTransaction = {}
            //   updatedTransaction.id = TRANSACTIONS[i].id
            //   updatedTransaction.organizationId = '5dee0e62-3d90-4dd6-8dfe-e4e0d08d86eb'
            //   await API.graphql(graphqlOperation(updateTransaction, {input: updatedTransaction}))
            //       .catch((err) => console.log(err))
            //   console.log('1 transaction updated')
            // }
            // console.log('all transactions updated')

            // Start Tutorial if new user
            // introJs().setOptions({
            //   steps: [
            //     {
            //       element: '.settings-button',
            //       intro: 'Click this button to open the settings page.',
            //     },
            //     {
            //       element: '#organization-tab',
            //       intro: 'Click this tab to go to the Organization settings.',
            //     },
            //     {
            //       element: '#organization-name',
            //       intro: 'Fill in the name of your organization here.',
            //     },
            //     {
            //       element: '#customization-tab',
            //       intro: 'Click this tab to go to the Customization settings.',
            //     },
            //     {
            //       element: '#customization-color',
            //       intro: 'Choose a color for your organization here.',
            //     },
            //     {
            //       element: '#members-tab',
            //       intro: 'Click this tab to go to the Members settings.',
            //     },
            //     {
            //       element: '#import-members',
            //       intro: 'Click this button to import members from a CSV file.',
            //     },
            //     {
            //       element: '#users-tab',
            //       intro: 'Click this tab to go to the Users settings.',
            //     },
            //     {
            //       element: '#add-user',
            //       intro: 'Click this button to add a new user.',
            //     },
            //     {
            //       element: '#organization-tab',
            //       intro: 'Click this tab to go back to the Organization settings.',
            //     },
            //     {
            //       element: '#enable-wishwell',
            //       intro: 'Check this box to enable WishWell for your organization.',
            //     },
            //   ],
            // }).start();
          }
        }
        else {
          this.loadPage()
          return
        }

      } catch (error) {
          Bugsnag.notify(error);
          console.log(error)
      }
      this.setState({
          allMembers: MEMBERS,
          userPermissions: userPermissions,
          memberDetailsLoading: false
      });
  }

  getSettings = async(userRecord) => {
    // Check for existing setting record
    var user = {}
    if (!userRecord) {
      const user_info = await Auth.currentUserInfo()
      const username = user_info.username
      user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
    }
    else {
      user = userRecord
    }
    
    if (user) {
      const orgId = user.data.userByUserName.items[0].organizationId
      const settings_record = await API.graphql(graphqlOperation(settingsByOrganizationId, { organizationId: orgId }))
      if (settings_record && settings_record.data.settingsByOrganizationId.items[0]) {
        return settings_record.data.settingsByOrganizationId.items[0]
      }
      else {
        this.loadPage()
      }
    }
    else {
      this.loadPage()
    }
    
  }

  DisplayMember(member) {
    this.setState({member: {
      id: member.id,
      pnumber: member.pnumber,
      mnumber: member.mnumber,
      ptype: member.ptype,
      pactive: member.pactive,
      pforms: member.pforms,
      eforms: member.eforms,
      pcards: member.pcards,
      ecards: member.ecards,
      birthdayBooster: member.birthdayBooster,
      eReminders: member.eReminders,
      fname: member.fname,
      lname: member.lname,
      email: member.email,
      phone: member.phone,
      address: member.address,
      addressRemote: member.addressRemote,
      DOB: member.DOB,
      anumber: member.anumber,
      apayment: member.apayment,
      notes: member.notes,
    }})
  }

  handleChange(event) {
    const target = event.target
    if (target.id === 'emailEForms') {
      var emailEForms = target.checked ? true : false
      this.setState({
        emailOrderForms: emailEForms
      })
    }
    if (target.id === 'emailCards') {
      var emailCards = target.checked ? true : false
      this.setState({
        emailCards: emailCards
      })
    }
    if (target.id === 'cAndEType') {
      this.setState({
        cAndEType: target.value
      })
    }
    // else {
    //     const field = target.name
    //     const value = target.value
    //     updatedMember[field] = value
    //     this.setState({
    //         member: updatedMember
    //     })
    // }
  }

  emailOrderForms = async () => {

    // Tracking
    mixpanel.track('Order forms emailed');

    const quarter = document.getElementById("order-quarter").value

    // Get orgId
    const user_info = await Auth.currentUserInfo()
    const username = user_info.username
    const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
    const orgId = user.data.userByUserName.items[0].organizationId

    var nextToken = null;
    var count = 0
    var filteredList
    var SETTINGS = []
    while (nextToken || count === 0) {
        count = 1
        filteredList = await API.graphql(
        graphqlOperation(listSettings, {
          filter: {
            organizationId: { eq: orgId }
          },
          limit: 900,
          nextToken:nextToken}))
        nextToken = filteredList.data.listSettings.nextToken
        SETTINGS = SETTINGS.concat(filteredList.data.listSettings.items)
    }

    var months = ''
    switch (quarter) {
      case 'Q2':
          months = 'April, May, and June';
          break;
      case 'Q3':
          months = 'July, August, and September';
          break;
      case 'Q4':
          months = 'October, November, and December';
          break;
      case 'Q1':
          months = 'January, February, and March';
          break;
      default:
          break;
          // quarter = 'Q1';
          // months = 'January, February, and March';
  }

    const subject = SETTINGS.length > 0 ? ' Here\'s your ' + SETTINGS[0].chapterName + ' WishWell Order Form' : 'Here\'s your WishWell Order Form!'

    for (var i=0; i < this.state.allMembers.length; i++) {
    // for (var i=0; i < 1; i++) {
      if (this.state.allMembers[i].eforms) {
        const options = {
          from: `${this.state.settings.chapterName} <wishwell@onegiftfoundation.org>`,
          replyTo: this.state.settings.orgEmail,
          to: this.state.allMembers[i].email,
          html: `<p> ${this.state.allMembers[i].fname},</p><p>It's time to place your ${this.state.settings.chapterName} wishes for ${quarter} events occuring in ${months}. Please head over to <a href="http://wishwell.ai/${this.state.settings.makeAWishURL}" target="_blank" rel="noopener noreferrer" >wishwell.ai/ ${this.state.settings.makeAWishURL}</a> to place your orders via secure credit card or ACH payment today!</p><p>If you're new to WishWell, you can get more information on how the process works at: <a href="http://wishwell.ai/wishestutorial" target="_blank" rel="noreferrer">wishwell.ai/wishestutorial</a></p><p>Wishes are due by the end of the month. Please contact your organization administrator at ${this.state.settings.orgEmail} if you don't want to get these reminders anymore. ${this.state.allMembers[i].pforms ? 'Or, if you prefer to go fully digital, you can email them to say that you would like to opt out of physical order forms going forward (if you haven\'t already).' : ''}</p><p>Happy Giving!</p><p>Your ${this.state.settings.chapterName} Team!</p><p><a href="https://www.onegiftfoundation.org" target="_blank" rel="noopener noreferrer">Learn more about WishWell and OneGift</a></p>`,
          subject: subject,
        };
        try {
          // Send email call to api
          const apiName = 'wishwellapi';
          var path = '/wishwellapi/sendEmail';
          var myInit = { // OPTIONAL
              body: {
                options: options
              },
              headers: {}, // OPTIONAL
          };
          await API.post(apiName, path, myInit)

        } catch (error) {
          Bugsnag.notify(error);
          toaster.danger('Members couldn\'t be emailed. Please try again or contact support', {duration: 5,})
        }
      }
    }
  }

  GenerateLabels = async (labelType) => {
    
    try {
      // Create document
      const doc = new Document();

      // Placeholder for members data to get labelmaker working
      var nextToken = null;
      var count = 0
      var filteredList
      var MEMBERS = []
      const user_info = await Auth.currentUserInfo()
      const username = user_info.username
      const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
      const orgId = user.data.userByUserName.items[0].organizationId
      let filter = {
          and: [{ ptype: { ne: 5 } },
                { pactive: { eq: "true"} },
                { pforms: { eq: "true"} },
                { DOB: { ne: "1900-01-01" } },
                { DOB: { ne: "" } }]
      };
      while (nextToken || count === 0) {
          count = 1
          filteredList = await API.graphql(
          graphqlOperation(membersByOrganizationId, {
            organizationId: orgId,
            filter: filter,
            limit: 900,
            nextToken:nextToken}))
          nextToken = filteredList.data.membersByOrganizationId.nextToken
          MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items)
      }
      
      // Sort membersForLabels by lastname
      MEMBERS.sort(function(a, b) {
        var textA = a.lname.toUpperCase();
        var textB = b.lname.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      // Get number of active members and concat fname to lname to name
      const memberCount = MEMBERS.length;
      var memberNameAndNumberForLabels = []
      for (var k = 0; k < memberCount; k++) {
        memberNameAndNumberForLabels[k] = MEMBERS[k].fname + " " + MEMBERS[k].lname + " " + MEMBERS[k].pnumber
      }

      if (labelType === "stickers") {
        // remove table borders
        const borders = {
          top: {
              style: BorderStyle.NONE,
              size: 1,
          },
          bottom: {
              style: BorderStyle.NONE,
              size: 1,
          },
          left: {
              style: BorderStyle.NONE,
              size: 1,
          },
          right: {
              style: BorderStyle.NONE,
              size: 1,
          },
        };

        // set table height and cell width
        const tableHeight = 385;
        const cellWidth = 3000;

        //var i = 0;
        for (var j = 0; j < (Math.floor((memberCount-1) / 30) + 1); j++) {
          var i = j*30
          const table = new Table({
            alignment: AlignmentType.CENTER,
            rows: [
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 1 > memberCount ? "" : memberNameAndNumberForLabels[i],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 2 > memberCount ? "" : memberNameAndNumberForLabels[i+1],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      cellWidth,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: (i + 3) > memberCount ? "" : memberNameAndNumberForLabels[i+2],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 1 > memberCount ? "" : MEMBERS[i].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 2 > memberCount ? "" : MEMBERS[i+1].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 3 > memberCount ? "" : MEMBERS[i+2].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 1 > memberCount ? "" : MEMBERS[i].address1.substring(MEMBERS[i].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 2 > memberCount ? "" : MEMBERS[i+1].address1.substring(MEMBERS[i+1].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 3 > memberCount ? "" : MEMBERS[i+2].address1.substring(MEMBERS[i+2].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight + 280,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 4 > memberCount ? "" : memberNameAndNumberForLabels[i+3],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 5 > memberCount ? "" : memberNameAndNumberForLabels[i+4],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      cellWidth,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: (i + 6) > memberCount ? "" : memberNameAndNumberForLabels[i+5],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 4 > memberCount ? "" : MEMBERS[i+3].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 5 > memberCount ? "" : MEMBERS[i+4].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 6 > memberCount ? "" : MEMBERS[i+5].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 4 > memberCount ? "" : MEMBERS[i+3].address1.substring(MEMBERS[i+3].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 5 > memberCount ? "" : MEMBERS[i+4].address1.substring(MEMBERS[i+4].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 6 > memberCount ? "" : MEMBERS[i+5].address1.substring(MEMBERS[i+5].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight + 280,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 7 > memberCount ? "" : memberNameAndNumberForLabels[i+6],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 8 > memberCount ? "" : memberNameAndNumberForLabels[i+7],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      cellWidth,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: (i + 9) > memberCount ? "" : memberNameAndNumberForLabels[i+8],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 7 > memberCount ? "" : MEMBERS[i+6].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 8 > memberCount ? "" : MEMBERS[i+7].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 9 > memberCount ? "" : MEMBERS[i+8].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 7 > memberCount ? "" : MEMBERS[i+6].address1.substring(MEMBERS[i+6].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 8 > memberCount ? "" : MEMBERS[i+7].address1.substring(MEMBERS[i+7].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 9 > memberCount ? "" : MEMBERS[i+8].address1.substring(MEMBERS[i+8].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight + 280,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 10 > memberCount ? "" : memberNameAndNumberForLabels[i+9],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 11 > memberCount ? "" : memberNameAndNumberForLabels[i+10],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      cellWidth,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: (i + 12) > memberCount ? "" : memberNameAndNumberForLabels[i+11],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 10 > memberCount ? "" : MEMBERS[i+9].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 11 > memberCount ? "" : MEMBERS[i+10].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 12 > memberCount ? "" : MEMBERS[i+11].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 10 > memberCount ? "" : MEMBERS[i+9].address1.substring(MEMBERS[i+9].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 11 > memberCount ? "" : MEMBERS[i+10].address1.substring(MEMBERS[i+10].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 12 > memberCount ? "" : MEMBERS[i+11].address1.substring(MEMBERS[i+11].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight + 280,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 13 > memberCount ? "" : memberNameAndNumberForLabels[i+12],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 14 > memberCount ? "" : memberNameAndNumberForLabels[i+13],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      cellWidth,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: (i + 15) > memberCount ? "" : memberNameAndNumberForLabels[i+14],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 13 > memberCount ? "" : MEMBERS[i+12].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 14 > memberCount ? "" : MEMBERS[i+13].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 15 > memberCount ? "" : MEMBERS[i+14].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 13 > memberCount ? "" : MEMBERS[i+12].address1.substring(MEMBERS[i+12].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 14 > memberCount ? "" : MEMBERS[i+13].address1.substring(MEMBERS[i+13].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 15 > memberCount ? "" : MEMBERS[i+14].address1.substring(MEMBERS[i+14].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight + 280,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 16 > memberCount ? "" : memberNameAndNumberForLabels[i+15],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 17 > memberCount ? "" : memberNameAndNumberForLabels[i+16],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      cellWidth,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: (i + 18) > memberCount ? "" : memberNameAndNumberForLabels[i+17],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 16 > memberCount ? "" : MEMBERS[i+15].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 17 > memberCount ? "" : MEMBERS[i+16].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 18 > memberCount ? "" : MEMBERS[i+17].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 16 > memberCount ? "" : MEMBERS[i+15].address1.substring(MEMBERS[i+15].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 17 > memberCount ? "" : MEMBERS[i+16].address1.substring(MEMBERS[i+16].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 18 > memberCount ? "" : MEMBERS[i+17].address1.substring(MEMBERS[i+17].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight + 280,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 19 > memberCount ? "" : memberNameAndNumberForLabels[i+18],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 20 > memberCount ? "" : memberNameAndNumberForLabels[i+19],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      cellWidth,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: (i + 21) > memberCount ? "" : memberNameAndNumberForLabels[i+20],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 19 > memberCount ? "" : MEMBERS[i+18].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 20 > memberCount ? "" : MEMBERS[i+19].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 21 > memberCount ? "" : MEMBERS[i+20].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 19 > memberCount ? "" : MEMBERS[i+18].address1.substring(MEMBERS[i+18].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 20 > memberCount ? "" : MEMBERS[i+19].address1.substring(MEMBERS[i+19].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 21 > memberCount ? "" : MEMBERS[i+20].address1.substring(MEMBERS[i+20].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight + 280,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 22 > memberCount ? "" : memberNameAndNumberForLabels[i+21],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 23 > memberCount ? "" : memberNameAndNumberForLabels[i+22],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      cellWidth,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: (i + 24) > memberCount ? "" : memberNameAndNumberForLabels[i+23],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 22 > memberCount ? "" : MEMBERS[i+21].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 23 > memberCount ? "" : MEMBERS[i+22].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 24 > memberCount ? "" : MEMBERS[i+23].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 22 > memberCount ? "" : MEMBERS[i+21].address1.substring(MEMBERS[i+21].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 23 > memberCount ? "" : MEMBERS[i+22].address1.substring(MEMBERS[i+22].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 24 > memberCount ? "" : MEMBERS[i+23].address1.substring(MEMBERS[i+23].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight + 280,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 25 > memberCount ? "" : memberNameAndNumberForLabels[i+24],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 26 > memberCount ? "" : memberNameAndNumberForLabels[i+25],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      cellWidth,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: (i + 27) > memberCount ? "" : memberNameAndNumberForLabels[i+26],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 25 > memberCount ? "" : MEMBERS[i+24].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 26 > memberCount ? "" : MEMBERS[i+25].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 27 > memberCount ? "" : MEMBERS[i+26].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 25 > memberCount ? "" : MEMBERS[i+24].address1.substring(MEMBERS[i+24].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 26 > memberCount ? "" : MEMBERS[i+25].address1.substring(MEMBERS[i+25].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 27 > memberCount ? "" : MEMBERS[i+26].address1.substring(MEMBERS[i+26].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight + 280,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 28 > memberCount ? "" : memberNameAndNumberForLabels[i+27],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 29 > memberCount ? "" : memberNameAndNumberForLabels[i+28],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      cellWidth,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: (i + 30) > memberCount ? "" : memberNameAndNumberForLabels[i+29],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 28 > memberCount ? "" : MEMBERS[i+27].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 29 > memberCount ? "" : MEMBERS[i+28].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 30 > memberCount ? "" : MEMBERS[i+29].address1.split(',')[0],
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
              new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 28 > memberCount ? "" : MEMBERS[i+27].address1.substring(MEMBERS[i+27].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 29 > memberCount ? "" : MEMBERS[i+28].address1.substring(MEMBERS[i+28].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: cellWidth,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: i + 30 > memberCount ? "" : MEMBERS[i+29].address1.substring(MEMBERS[i+29].address1.indexOf(',') + 2),
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              }),
          ],
          width: {
              size: 92,
              type: WidthType.PERCENTAGE,
          },
        });
        doc.addSection({
          margins: {
            top: "0.5",
            right: "0.19",
            bottom: "0",
            left: 0.19,
          },
            children: [
              table,
            ],
        });
      }
      }
      else {
        const orderMailerImage = Media.addImage(
          doc,
          Uint8Array.from(atob(orderMailerImageBase64), c => c.charCodeAt(0)),
          700,
          150,
        );
        // Create birthday related elements
        const orderMailerImageParagraph = new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            orderMailerImage,
            new TextRun({
              break: 1
            })
          ]
        });
        for (i=0; i < memberCount; i++) {
          // Generate Cover Page (for double sided mailer)
          const mailingSideParagraph1 = new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: "To:  " + MEMBERS[i].fname + " " + MEMBERS[i].lname + "     Your PAP Corp # is " + MEMBERS[i].pnumber,
                size: 35,
                bold: true
              }),
              new TextRun({
                text: "Please note: All checks made out to PAP must have your “PAP Corp #“ on it.",
                size: 24,
                bold: true,
                italics: true,
                break: 1
              }),
              new TextRun({
                text: "Thank you for being a member!",
                size: 24,
                bold: true,
                italics: true,
                break: 2
              }),
              new TextRun({
                text: "If you have not yet renewed your membership, please do so ASAP. Help us in our FIGHT TO FIND A CURE",
                size: 20,
                bold: true,
                break: 2
              }),
              new TextRun({
                text: "Your membership gives you priority access to doctors at the Sylvester Comprehensive Cancer Center and Jackson Memorial "
                + "Hospital as well as a network of information to help you in making difficult decisions should the need arise. If you would like to "
                + "see a specialist (regarding any illness) affiliated with Sylvester Comprehensive Cancer Center or Jackson Memorial Hospital, "
                + "first call Marilyn Ochs @ 375-9577 to make your appointment. Following this procedure will result in your getting a priority "
                + "appointment sooner than if you or your doctor make the call.",
                size: 18,
                bold: true,
                italics: true,
                break: 2
              }),
              new TextRun({
                text: "Support us in our FUND-raising events!",
                size: 30,
                bold: true,
                italics: true,
                break: 2
              }),
              new TextRun({
                text: "PLEASE HELP US UPDATE OUR RECORDS. IF YOU HAVE A NORTHERN ADDRESS, PLEASE ENTER OR UPDATE BELOW",
                size: 18,
                bold: true,
                italics: true,
                break: 2
              }),
              new TextRun({
                text: "IF THE NORTHERN ADDRESS SHOULD BE REMOVED, PLEASE LET US KNOW AS WELL.",
                size: 12,
                bold: true,
                break: 1
              }),
              new TextRun({
                text: "_________________________________________  _____________________________________  _____________________",
                size: 18,
                bold: true,
                break: 2
              }),
              new TextRun({
                text: "  STREET                                                                      CITY - STATE - ZIP                                                 cell",
                size: 18,
                bold: true,
                break: 1
              }),
              new TextRun({
                text: "Flyers for all events will be in the clubhouse and on the web page.",
                size: 24,
                bold: true,
                break: 2
              }),
              new TextRun({
                text: "_______________",
                size: 30,
                break: 2
              })
            ]
          })
          const mailingSideParagraph2 = new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: MEMBERS[i].fname + " " + MEMBERS[i].lname,
                size: 40,
                bold: true,
                break: 2
              }),
              new TextRun({
                text: "OR CURRENT RESIDENT",
                size: 20,
                bold: true,
                break: 1
              }),
              new TextRun({
                text: MEMBERS[i].address1.split(',')[0],  // Street Address
                size: 28,
                break: 1
              }),
              new TextRun({
                text: MEMBERS[i].address1.substring(MEMBERS[i].address1.indexOf(',') + 2), // City, State ZIP
                size: 28,
                break: 1
              })
            ]
          })
          const mailingLabelChildren = [];
          mailingLabelChildren.push(mailingSideParagraph1, orderMailerImageParagraph, mailingSideParagraph2);

          doc.addSection({
            margins: {top: 720, right: 720, bottom: 720, left: 720},
            children: mailingLabelChildren
          })
        }
      }
      // Save docx file from browser      
    Packer.toBlob(doc).then((blob) => {
      // saveAs from FileSaver will download the file
      FileSaver(blob, "WishWell Mailing Labels.docx");
    });
    } catch (error) {
        Bugsnag.notify(error);
        toaster.danger('Label generation failed: ' + error + '. If issue continues, contact experience@onegiftfoundation.org for support.', {duration: 5,})
    }
  }

  GenerateOrderForms = async () => {

    // Tracking
    mixpanel.track('Labels and order forms generated');

    const labelType = document.getElementById("order-labels").value
    await this.GenerateLabels(labelType)
    // email order forms
    if (this.state.emailOrderForms) {
      this.emailOrderForms()
    }

    const quarter = document.getElementById("order-quarter").value
      // Generate Order Forms in MS word
      try {
        const doc = new Document()

        // Get birthday/anniversary banners as Base64 - to convert png to base64: https://codesandbox.io/s/convert-file-to-base64-in-react-lqi1e
        
        const birthdayImage = Media.addImage(
          doc,
          Uint8Array.from(atob(birthdayImageBase64), c => c.charCodeAt(0)),
          700,
          100,
        );
        
        const anniversaryImage = Media.addImage(
          doc,
          Uint8Array.from(atob(anniversaryImageBase64), c => c.charCodeAt(0)),
          700,
          100,
        );

        // Retrieve active members
        var nextToken = null;
        var count = 0
        var filteredList
        var MEMBERS = []
        const user_info = await Auth.currentUserInfo()
        const username = user_info.username
        const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
        const orgId = user.data.userByUserName.items[0].organizationId
        let filter = {
            and: [{ pactive: { eq: "true"} },
                  { DOB: { ne: "1900-01-01" } },
                  { DOB: { ne: "" } }]
        };
        while (nextToken || count === 0) {
            count = 1
            filteredList = await API.graphql(
            graphqlOperation(membersByOrganizationId, {
              organizationId: orgId,
              filter: filter,
              limit: 900,
              nextToken:nextToken}))
            nextToken = filteredList.data.membersByOrganizationId.nextToken
            MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items)
        }

        // sort members
        MEMBERS.sort(function(a, b) {
          var textA = a.lname.toUpperCase();
          var textB = b.lname.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        const memberCount = MEMBERS.length

        // Set months of quarter
        var quarterMonths = []
        switch(quarter) {
          case "Q1":
            quarterMonths = ["January", "February", "March"]
            break;
          case "Q2":
            quarterMonths = ["April", "May", "June"]
            break;
          case "Q3":
            quarterMonths = ["July", "August", "September"]
            break;
          case "Q4":
            quarterMonths = ["October", "November", "December"]
            break;
          default: 
            break;
        }
        
        // Filter birthdays and anniversaries by quarter
        var quarterBirthdays = []
        var quarterAnniversaries = []
        for (var i = 0; i < memberCount; i++) {
          switch(quarter) {
            case "Q1":
              if (MEMBERS[i].DOB.split("-")[1] >= 1 &&
                  MEMBERS[i].DOB.split("-")[1] <= 3) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
              break;
            case "Q2":
              if (MEMBERS[i].DOB.split("-")[1] >= 4 &&
                  MEMBERS[i].DOB.split("-")[1] <= 6) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
              break;
            case "Q3":
              if (MEMBERS[i].DOB.split("-")[1] >= 7 &&
                  MEMBERS[i].DOB.split("-")[1] <= 9) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
              break;
            case "Q4":
              if (MEMBERS[i].DOB.split("-")[1] >= 10 &&
                  MEMBERS[i].DOB.split("-")[1] <= 12) {
                    if (MEMBERS[i].ptype !== 5) {
                      quarterBirthdays.push(MEMBERS[i])
                    }
                    else {
                      quarterAnniversaries.push(MEMBERS[i])
                    }
                  }
              break;
            default:
              // code block
          }
        }

        // Get string versions of pnumber, fname, month, and day
        var quarterBirthdaysForTable = [[]]
        for (i = 0; i < quarterBirthdays.length; i++) {
          quarterBirthdaysForTable[i].pnumber = quarterBirthdays[i].pnumber ? quarterBirthdays[i].pnumber.toString(): '';
          quarterBirthdaysForTable[i].fname = quarterBirthdays[i].fname + " " + quarterBirthdays[i].lname;
          quarterBirthdaysForTable[i].month = quarterBirthdays[i].DOB.split("-")[1];
          quarterBirthdaysForTable[i].day = quarterBirthdays[i].DOB.split("-")[2];
          quarterBirthdaysForTable.push({
            pnumber: "",
            fname: "",
            month: "",
            day: "",
          });
        }
        quarterBirthdaysForTable.pop()

        var quarterAnniversariesForTable = [[]];
        for (i = 0; i < quarterAnniversaries.length; i++) {
          quarterAnniversariesForTable[i].pnumber = quarterAnniversaries[i].pnumber ? quarterAnniversaries[i].pnumber.toString() : '';
          quarterAnniversariesForTable[i].fname = quarterAnniversaries[i].fname + " " + quarterAnniversaries[i].lname;
          quarterAnniversariesForTable[i].month = quarterAnniversaries[i].DOB.split("-")[1];
          quarterAnniversariesForTable[i].day = quarterAnniversaries[i].DOB.split("-")[2];
          quarterAnniversariesForTable.push({
            pnumber: "",
            fname: "",
            month: "",
            day: "",
          });
        }
        quarterAnniversariesForTable.pop()

        // Create 2D arrays of Months -> Members
        var quarterBirthdaysByMonth = [[],[],[]]
        for (i = 0; i < quarterBirthdaysForTable.length; i++) {
          if (quarterBirthdaysForTable[i].month === '01' || 
              quarterBirthdaysForTable[i].month === '04' || 
              quarterBirthdaysForTable[i].month === '07' || 
              quarterBirthdaysForTable[i].month === '10') {
            quarterBirthdaysByMonth[0].push(quarterBirthdaysForTable[i])
          }
          else if (quarterBirthdaysForTable[i].month === '02' || 
          quarterBirthdaysForTable[i].month === '05' || 
          quarterBirthdaysForTable[i].month === '08' || 
          quarterBirthdaysForTable[i].month === '11') {
            quarterBirthdaysByMonth[1].push(quarterBirthdaysForTable[i])
          }
          else if (quarterBirthdaysForTable[i].month === '03' || 
          quarterBirthdaysForTable[i].month === '06' || 
          quarterBirthdaysForTable[i].month === '09' || 
          quarterBirthdaysForTable[i].month === '12') {
            quarterBirthdaysByMonth[2].push(quarterBirthdaysForTable[i])
          }
        }

        var quarterAnniversariesByMonth = [[],[],[]]
        for (i = 0; i < quarterAnniversariesForTable.length; i++) {
          if (quarterAnniversariesForTable[i].month === '01' || 
          quarterAnniversariesForTable[i].month === '04' || 
          quarterAnniversariesForTable[i].month === '07' || 
          quarterAnniversariesForTable[i].month === '10') {
            quarterAnniversariesByMonth[0].push(quarterAnniversariesForTable[i])
          }
          else if (quarterAnniversariesForTable[i].month === '02' || 
          quarterAnniversariesForTable[i].month === '05' || 
          quarterAnniversariesForTable[i].month === '08' || 
          quarterAnniversariesForTable[i].month === '11') {
            quarterAnniversariesByMonth[1].push(quarterAnniversariesForTable[i])
          }
          else if (quarterAnniversariesForTable[i].month === '03' || 
          quarterAnniversariesForTable[i].month === '06' || 
          quarterAnniversariesForTable[i].month === '09' || 
          quarterAnniversariesForTable[i].month === '12') {
            quarterAnniversariesByMonth[2].push(quarterAnniversariesForTable[i])
          }
        }

        // set table borders
        const borders = {
          top: {
              style: BorderStyle.SINGLE,
              size: 1,
          },
          bottom: {
              style: BorderStyle.SINGLE,
              size: 1,
          },
          left: {
              style: BorderStyle.SINGLE,
              size: 1,
          },
          right: {
              style: BorderStyle.SINGLE,
              size: 1,
          },
        };

        // set all table height and cell width
        const tableHeight = 250;

        const bRows = []

        // Set birthday table headers
        const bRow = new TableRow({
          children: [
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "No",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  //size: 200,
                  type: WidthType.AUTO
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: quarterMonths[0],
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "Day",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  //size: 100,
                  type: WidthType.AUTO
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "X",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  //size: 100,
                  type: WidthType.AUTO
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "No",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  //size: 200,
                  type: WidthType.AUTO
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: quarterMonths[1],
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "Day",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  //size: 100,
                  type: WidthType.AUTO
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "X",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  //size: 100,
                  type: WidthType.AUTO
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "No",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  //size: 200,
                  type: WidthType.AUTO
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: quarterMonths[2],
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "Day",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  //size: 100,
                  type: WidthType.AUTO
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "X",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
          ],
          height: {
            height: tableHeight,
            rule: HeightRule.EXACT,
          },
        })
        bRows.push(bRow)

        // Populate birthday table
        const bTableRows = Math.max(quarterBirthdaysByMonth[0].length, quarterBirthdaysByMonth[1].length, quarterBirthdaysByMonth[2].length)
        for (i = 0; i < bTableRows; i++) {
          const bRow = new TableRow({
            children: [
                new TableCell({
                  borders,
                  width: {
                    //size: 50,
                    type: WidthType.AUTO
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterBirthdaysByMonth[0][i] ? quarterBirthdaysByMonth[0][i].pnumber : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 300,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterBirthdaysByMonth[0][i] ? quarterBirthdaysByMonth[0][i].fname : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 100,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterBirthdaysByMonth[0][i] ? quarterBirthdaysByMonth[0][i].day : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    //size: 100,
                    type: WidthType.AUTO
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    //size: 100,
                    type: WidthType.AUTO
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    //size: 50,
                    type: WidthType.AUTO
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterBirthdaysByMonth[1][i] ? quarterBirthdaysByMonth[1][i].pnumber : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 300,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterBirthdaysByMonth[1][i] ? quarterBirthdaysByMonth[1][i].fname : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 100,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterBirthdaysByMonth[1][i] ? quarterBirthdaysByMonth[1][i].day : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    //size: 100,
                    type: WidthType.AUTO
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    //size: 100,
                    type: WidthType.AUTO
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    //size: 50,
                    type: WidthType.AUTO
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterBirthdaysByMonth[2][i] ? quarterBirthdaysByMonth[2][i].pnumber : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 300,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterBirthdaysByMonth[2][i] ? quarterBirthdaysByMonth[2][i].fname : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 100,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterBirthdaysByMonth[2][i] ? quarterBirthdaysByMonth[2][i].day : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    //size: 100,
                    type: WidthType.AUTO
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
            ],
            height: {
              height: tableHeight,
              rule: HeightRule.EXACT,
            },
          })
          bRows.push(bRow)
        }

        //Generate birthday tables
        const birthdayTable = new Table({
          alignment: AlignmentType.CENTER,
          rows: bRows,
          width: {
            size: 105,
            type: WidthType.PERCENTAGE,
          },
        });

        const aRows = []

        // Set anniversary table headers
        const aRow = new TableRow({
          children: [
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "No",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 40,
                  type: WidthType.DXA
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: quarterMonths[0],
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "Day",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "X",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  //size: 10,
                  type: WidthType.AUTO
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "No",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 400,
                  type: WidthType.DXA
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: quarterMonths[1],
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "Day",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "X",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  //size: 10,
                  type: WidthType.AUTO
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "No",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 400,
                  type: WidthType.DXA
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: quarterMonths[2],
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "Day",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
              new TableCell({
                borders,
                width: {
                  size: 5,
                  type: WidthType.PERCENTAGE
                },
                children: [new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                     text: "X",
                     bold: true,
                     size: 24,
                    }),
                  ]
                })],
              }),
          ],
          height: {
            height: 500,
            rule: HeightRule.EXACT,
          },
        })
        aRows.push(aRow)

        // Populate anniversary table
        const aTableRows = Math.max(quarterAnniversariesByMonth[0].length, quarterAnniversariesByMonth[1].length, quarterAnniversariesByMonth[2].length)
        for (i = 0; i < aTableRows; i++) {
          const aRow = new TableRow({
            children: [
                new TableCell({
                  borders,
                  width: {
                    size: 50,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterAnniversariesByMonth[0][i] ? quarterAnniversariesByMonth[0][i].pnumber : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 200,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterAnniversariesByMonth[0][i] ? quarterAnniversariesByMonth[0][i].fname : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 100,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterAnniversariesByMonth[0][i] ? quarterAnniversariesByMonth[0][i].day : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 100,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: "",
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    //size: 100,
                    type: WidthType.AUTO
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: "",
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 50,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterAnniversariesByMonth[1][i] ? quarterAnniversariesByMonth[1][i].pnumber : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 200,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterAnniversariesByMonth[1][i] ? quarterAnniversariesByMonth[1][i].fname : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 100,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterAnniversariesByMonth[1][i] ? quarterAnniversariesByMonth[1][i].day : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 100,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: "",
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    //size: 100,
                    type: WidthType.AUTO
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: "",
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 50,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterAnniversariesByMonth[2][i] ? quarterAnniversariesByMonth[2][i].pnumber : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 200,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterAnniversariesByMonth[2][i] ? quarterAnniversariesByMonth[2][i].fname : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 100,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: quarterAnniversariesByMonth[2][i] ? quarterAnniversariesByMonth[2][i].day : "",
                       size: 24,
                      }),
                    ]
                  })],
                }),
                new TableCell({
                  borders,
                  width: {
                    size: 100,
                    type: WidthType.DXA
                  },
                  children: [new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                       text: "",
                      }),
                    ]
                  })],
                }),
            ],
            height: {
              height: 500,
              rule: HeightRule.EXACT,
            },
          })
          aRows.push(aRow)
        }

        //Generate anniversary tables
        const anniversaryTable = new Table({
          alignment: AlignmentType.CENTER,
          rows: aRows,
          width: {
            size: 105,
            type: WidthType.PERCENTAGE,
          },
        });

        // Compile Order Forms
        const orderFormChildren = [];

        // Create birthday related elements
        const birthdayOFImageParagraph = new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            birthdayImage,
          ]
        });
        const birthdayOFDearParagraph = new Paragraph({
          children: [new TextRun({
            text: "       Dear Member,",
            size: 24,})]
        });
        const birthdayOFTextParagraph = new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "         Listed below are lists of PAP Chapter members with birthdays that occur during the months of ",
              size: 24,
            }),
            new TextRun({
              text: quarterMonths[0] + ", " + quarterMonths[1] + ", and " + quarterMonths[2] + ", ENTERYEAR",
              size: 40,
              bold: true,
            break: 1,}),
            new TextRun({
              text: "Your name will be included in a personalized birthday card that will be sent to the member(s) of your choice for a donation of $1.00 per greeting. Put an X next to the names of your choice and submit this form with your check payable to: ",
              size: 24,
              break: 1,
            }),
            new TextRun({
              text: "The PAP Corps.",
              size: 24,
              bold: true}),
            new TextRun({
              text: "Place this completed form with your check (one check per family) and mail to " + this.state.settings.chapterName + ", " + this.state.settings.envelopeReturnAddressLine1 + ", " + this.state.settings.envelopeReturnAddressLine2 + " or deposited into a box by the front door. ",
              size: 24,
              break: 1,
            }),
            new TextRun({
              text: "FULL DONOR CREDIT WILL BE ISSUED TO YOU",
              break: 2,
            }),
            new TextRun({
              text: "We must receive your selection and check no later than INSERT DUE DATE ",
              size: 24,
              bold: true,
            break: 1,}),
            new TextRun({
              text: "Member Name:_________________________ (one name only) Pap no.__________",
              break: 2,
              size: 24,
              bold: true,
            }),
            new TextRun({
              text: "Total number of checked names: ______ times $1.00 equals $ ___________  Check No: ________",
              size: 24,
              bold: true,
              break: 2,
            }),
          ]
        });

        // Add birthday elements to order form document children
        orderFormChildren.push(birthdayOFImageParagraph, birthdayOFDearParagraph, birthdayOFTextParagraph, birthdayTable);

        // Check if any anniversary members for quarter
        // If any generate the form, otherwise do not
        if (quarterAnniversariesByMonth[0].length > 0 
          || quarterAnniversariesByMonth[1].length > 0 
         || quarterAnniversariesByMonth[2].length > 0) {
          const pageBreak = new Paragraph({
            pageBreakBefore: true,
          });
          const anniversaryOFImageParagraph = new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              anniversaryImage,
            ],
          });
          const anniversaryOFDearParagraph = new Paragraph({children: [new TextRun({
            text: "       Dear Member,",
            size: 24
          }),]});
          const anniversaryOFTextParagraph = new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: "         Listed below are lists of PAP Chapter members with anniversaries that occur during the months of ",
                size: 24,
              }),
              new TextRun({
                text: quarterMonths[0] + ", " + quarterMonths[1] + ", and " + quarterMonths[2] + ", ENTERYEAR",
                size: 40,
                bold: true,
              break: 1,}),
              new TextRun({
                text: "Your name will be included in a personalized anniversary card that will be sent to the member(s) of your choice for a donation of $1.00 per greeting. Put an X next to the names of your choice and submit this form with your check payable to: ",
                size: 24,
                break: 1,
              }),
              new TextRun({
                text: "The PAP Corps.",
                size: 24,
                bold: true,}),
              new TextRun({
                text: "Place this completed form with your check (one check per family) and mail to " + this.state.settings.chapterName + ", " + this.state.settings.envelopeReturnAddressLine1 + ", " + this.state.settings.envelopeReturnAddressLine2 + " or deposited into a box by the front door. ",
                size: 24,
                break: 1,
              }),
              new TextRun({
                text: "FULL DONOR CREDIT WILL BE ISSUED TO YOU",
                break: 2,
              }),
              new TextRun({
                text: "We must receive your selection and check no later than INSERT DUE DATE ",
                size: 24,
                bold: true,
              break: 1,}),
              new TextRun({
                text: "Member Name:_________________________ (one name only) Pap no.__________",
                break: 2,
                size: 24,
                bold: true,
              }),
              new TextRun({
                text: "Total number of checked names: ______ times $1.00 equals $ ___________  Check No: ________",
                size: 24,
                bold: true,
                break: 2,
              }),
            ]
          });

           // Add anniversary elements to order form document children
          orderFormChildren.push(pageBreak, anniversaryOFImageParagraph, anniversaryOFDearParagraph, anniversaryOFTextParagraph, anniversaryTable);
        }

        doc.addSection({
          margins: {top: 720, right: 720, bottom: 720, left: 720},
          children: orderFormChildren
        })

        Packer.toBlob(doc).then((blob) => {
          // saveAs from FileSaver will download the file
          FileSaver(blob, "WishWell Order Forms.docx");
        });

        toaster.success('Order forms and labels generated successfully!', {duration: 5,})
      } catch (error) {
        Bugsnag.notify(error);
        toaster.danger('Order form generator failed: ' + error + '. If issue continues, contact experience@onegiftfoundation.org for support.', {duration: 5,})
      }
      this.setState({
        generateOrderFormsModalShown: false,
      })
  }

  GenerateCandE = async () => {
    // Tracking
    mixpanel.track('Cards and envelopes generated');

    const CandEType = document.getElementById("cAndEType").value
    if (this.state.emailCards && window.confirm('Are you sure you want to email cards for the selected month? If you have not already verified your organization\'s email, the cards will be emailed immediately from experience@onegiftfoundation.org, and so we strongly suggest notifying the recipients beforehand to ensure that they don\'t ignore or mark the emails as spam. \n\nWishWell will also generate a zip file with all of the individual cards as Word files for your records. \n\nMore info is available at https://www.onegiftfoundation.org/resources/emails')) {
      await this.emailCards()
    }
    if (CandEType === "ManyFiles") {
      await this.GenerateManyCandE()
    }
    else if (CandEType === "OneFile") {
      await this.GenerateIndividualCandE()
    }
    this.setState({
      generateModalShown: false,
    })
  }

  emailCards = async () => {

    // Tracking
    mixpanel.track('Cards emailed');

    try {
      // Get/set month and year for cards
      const month = document.getElementById("month").value
      var monthLiteral = ''
      switch(month) {
        case '01':
          monthLiteral = 'January'
          break;
        case '02':
          monthLiteral = 'February'
          break;
        case '03':
          monthLiteral = 'March'
          break;
        case '04':
          monthLiteral = 'April'
          break;
        case '05':
          monthLiteral = 'May'
          break;
        case '06':
          monthLiteral = 'June'
          break;
        case '07':
          monthLiteral = 'July'
          break;
        case '08':
          monthLiteral = 'August'
          break;
        case '09':
          monthLiteral = 'September'
          break;
        case '10':
          monthLiteral = 'October'
          break;
        case '11':
          monthLiteral = 'November'
          break;
        case '12':
          monthLiteral = 'December'
          break;
        default:
          break;
      }
      const year = document.getElementById("year").value

      // Get members and orders
      var nextToken = null;
      var count = 0
      var filteredList
      var MEMBERS = []
      const user_info = await Auth.currentUserInfo()
      const username = user_info.username
      const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
      const orgId = user.data.userByUserName.items[0].organizationId
      let filter = {
          and: [{ pactive: { eq: "true"} },
                { DOB: { ne: "1900-01-01" } }]
      };
      while (nextToken || count === 0) {
          count = 1
          filteredList = await API.graphql(
          graphqlOperation(membersByOrganizationId, {
            organizationId: orgId,
            filter: filter,
            limit: 900,
            nextToken:nextToken}))
          nextToken = filteredList.data.membersByOrganizationId.nextToken
          MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items)
      }

      MEMBERS.sort(function(a, b) {
        var textA = a.lname.toUpperCase();
        var textB = b.lname.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      nextToken = null;
      count = 0
      var filteredOrders
      var ORDERS = []
      let oFilter = {
        and: [{ year: { eq: year} }]
    };
      while (nextToken || count === 0) {
          count = 1
          filteredOrders = await API.graphql(
          graphqlOperation(ordersByOrganizationId, {
            organizationId: orgId,
            filter: oFilter,
            limit: 900,
            nextToken:nextToken}))
          nextToken = filteredOrders.data.ordersByOrganizationId.nextToken
          ORDERS = ORDERS.concat(filteredOrders.data.ordersByOrganizationId.items)
      }
      
      // Get actual date of order
      for (var i = 0; i < ORDERS.length; i++) {
        // Updated at holds actual date record of order
        ORDERS[i].updatedAt = (new Date(ORDERS[i].createdAt.split('-')[0], ORDERS[i].createdAt.split('-')[1]-1))
      }

      // Create arrays of bday and anniversary receivers
      var birthdayCardReceiver = [[]]
      var anniversaryCardReceiver = [[]]
      birthdayCardReceiver[0][0] = ORDERS[1]
      for (i = 0; i < MEMBERS.length; i++) {
        if (MEMBERS[i].DOB.split('-')[1] === month && MEMBERS[i].ecards === true) {
              if (MEMBERS[i].ptype === 5) {
                anniversaryCardReceiver.push(MEMBERS[i])
              }
              else {
                birthdayCardReceiver.push(MEMBERS[i])
              }
        }
      }

      var date = new Date()

      // Create arrays with all gifter/donor pnumbers
      var birthdayGifters = []
      for (i = 0; i < birthdayCardReceiver.length; i++) {
        birthdayGifters[i] = []
        for (var m = 0; m < ORDERS.length; m++) {
          if (birthdayCardReceiver[i].pnumber === ORDERS[m].receiverPNumber &&
              (date - ORDERS[m].updatedAt)/(1000*60*60*24*30.5) < 12) {
                for (var n = 0; n < MEMBERS.length; n++) {
                  if (ORDERS[m].donorPNumber) {
                    if (MEMBERS[n].pnumber === ORDERS[m].donorPNumber) {
                      birthdayGifters[i].push(MEMBERS[n].fname + ' ' + MEMBERS[n].lname)
                    }
                  }
                  else {
                    ORDERS[m].donorName && birthdayGifters[i].push(ORDERS[m].donorName)
                  }
                }
          }
        }
      }

      for (i = 0; i < birthdayGifters.length; i++) {

        // Add padded members if less than 5 wishes
        if (birthdayGifters[i].length < 5 && this.state.settings.defaultWishers.length > 0) {
          const defaultWisherValues = this.state.settings.defaultWishers.map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`))
          const defaultWishers = defaultWisherValues.map(obj => obj.label)
          birthdayGifters[i].unshift(...defaultWishers)
        }
        birthdayGifters[i].sort(function(a, b) {
          var textA = a.split(" ")[a.split(" ").length - 1];
          var textB = b.split(" ")[b.split(" ").length - 1];
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }

      var anniversaryGifters = []
      for (i = 0; i < anniversaryCardReceiver.length; i++) {
        anniversaryGifters[i] = []
        for (m = 0; m < ORDERS.length; m++) {
          if (anniversaryCardReceiver[i].pnumber === ORDERS[m].receiverPNumber &&
              (date - ORDERS[m].updatedAt)/(1000*60*60*24*30.5) < 12) {
                for (n = 0; n < MEMBERS.length; n++) {
                  if (ORDERS[m].donorPNumber) {
                    if (MEMBERS[n].pnumber === ORDERS[m].donorPNumber) {
                      anniversaryGifters[i].push(MEMBERS[n].fname + ' ' + MEMBERS[n].lname)
                    }
                  }
                  else {
                    ORDERS[m].donorName && anniversaryGifters[i].push(ORDERS[m].donorName)
                  }
                }
          }
        }
      }

      for (i = 0; i < anniversaryGifters.length; i++) {

        // Add padded members if less than 5 wishes
        if (anniversaryGifters[i].length < 5 && this.state.settings.defaultWishers.length > 0) {
          const defaultWisherValues = this.state.settings.defaultWishers.map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`))
          const defaultWishers = defaultWisherValues.map(obj => obj.label)
          anniversaryGifters[i].unshift(...defaultWishers)
        }
        anniversaryGifters[i].sort(function(a, b) {
          var textA = a.split(" ")[a.split(" ").length - 1];
          var textB = b.split(" ")[b.split(" ").length - 1];
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }

      // create docs and download
      {
        var zip = new JSZip()
        const zipFilename = monthLiteral + ' ' + year.toString() + ' - Emailed PAP Cards.zip'
        
        // Set standard properties
        const borders = {
          top: {
              style: BorderStyle.NONE,
              size: 1,
          },
          bottom: {
              style: BorderStyle.NONE,
              size: 1,
          },
          left: {
              style: BorderStyle.NONE,
              size: 1,
          },
          right: {
              style: BorderStyle.NONE,
              size: 1,
          },
        };  
        const tableHeight = 700;
        // const cellWidth = 3000;

        // create array of pages and iterate through receivers
        // const cards = new Array()

        // Get orgId
        const user_info = await Auth.currentUserInfo()
        const username = user_info.username
        const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
        const orgId = user.data.userByUserName.items[0].organizationId

        nextToken = null;
        count = 0
        var SETTINGS = []
        while (nextToken || count === 0) {
            count = 1
            filteredList = await API.graphql(
            graphqlOperation(listSettings, {
              filter: {
                organizationId: { eq: orgId }
              },
              limit: 900,
              nextToken:nextToken}))
            nextToken = filteredList.data.listSettings.nextToken
            SETTINGS = SETTINGS.concat(filteredList.data.listSettings.items)
        }

        // Birthday cards
        for (i = 1; i < birthdayCardReceiver.length; i++) {
          const Rows = []
          // Setup new birthday document
          const bCardDoc = new Document();

          // Setup background image
          const birthdayBackgroundImage = Media.addImage(
            bCardDoc,
            Uint8Array.from(atob(birthdayBackgroundBase64), c => c.charCodeAt(0)),
            700,
            1000,
            {
              floating: {
                horizontalPosition: {
                    offset: 360000, // relative: HorizontalPositionRelativeFrom.PAGE by default
                },
                verticalPosition: {
                    offset: 360000, // relative: VerticalPositionRelativeFrom.PAGE by default
                },
                behindDocument: true,
              },
            },
          );

          if (birthdayGifters[i].length === 0) {
            //Don't need to generate doc if no gifters
            continue;
          }
          else if (birthdayGifters[i].length <= 10) {
            
            for (var j = 0; j < birthdayGifters[i].length; j++) {
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 500,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: birthdayGifters[i][j],
                          size: 72,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size: 60,
                type: WidthType.PERCENTAGE,
              },
            });
            //Rows.splice(0,Rows.length)

            bCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: [
                new Paragraph(birthdayBackgroundImage),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    // birthdayBackgroundImage,
                  ],
                }),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun({
                    text: year,
                    size: 50,
                    font: "Monotype Corsiva"
                  })]
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Happy Birthday",
                      size: 120,
                      font: "Monotype Corsiva",
                    break: 0,}),
                    new TextRun({
                      // Receiver name and font
                      text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                      size: 100,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "Best wishes from your friends at",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: this.state.settings.chapterName,
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                  ]
                }),
                gifterTable,
              ],
            })
          }
          else if (birthdayGifters[i].length <= 20) {
            
              for (j = 0; j < Math.ceil(birthdayGifters[i].length / 2); j++) {
                var k = j*2
                const Row = new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: birthdayGifters[i][k],
                            size: 56,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: birthdayGifters[i][k+1] ? birthdayGifters[i][k+1] : "",
                            size: 56,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight,
                    rule: HeightRule.EXACT,
                  },
                })
                Rows.push(Row)
              }

              const gifterTable = new Table({
                alignment: AlignmentType.CENTER,
                rows: Rows,
                width: {
                  size: 80,
                  type: WidthType.PERCENTAGE,
                },
              });
              //Rows.splice(0,Rows.length)

              bCardDoc.addSection({
                margins: {
                  top: 720,
                  right: 720,
                  bottom: 720,
                  left: 720,
                },
                children: [
                  new Paragraph(birthdayBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      // birthdayBackgroundImage,
                    ],
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Birthday",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "Best wishes from your friends at",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: this.state.settings.chapterName,
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  }),
                  gifterTable,
                ],
              })
          }
          else if (birthdayGifters[i].length > 20) {

            var longBCardChildren = [
              new Paragraph(birthdayBackgroundImage),
              new Paragraph({
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [new TextRun({
                  text: year,
                  size: 50,
                  font: "Monotype Corsiva"
                })]
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Happy Birthday",
                    size: 120,
                    font: "Monotype Corsiva",
                  break: 0,}),
                  new TextRun({
                    text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                    size: 100,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "Best wishes from your friends at",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: this.state.settings.chapterName,
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                ]
              }),
            ]
            
            for (j = 0; j < Math.ceil(birthdayGifters[i].length / 3); j++) {
              k = j*3
              if ((j % 13 === 0) &&
                  j !== 0) {

                const gifterTable = new Table({
                  alignment: AlignmentType.CENTER,
                  rows: Rows,
                  width: {
                    size: 90,
                    type: WidthType.PERCENTAGE,
                  },
                });
                Rows.length = 0

                longBCardChildren.push(
                  gifterTable,
                  new Paragraph({
                    pageBreakBefore: true,
                  }),
                  new Paragraph(birthdayBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Birthday",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "more friends...",
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  })
                )
              }
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: birthdayGifters[i][k],
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: birthdayGifters[i][k+1] ? birthdayGifters[i][k+1] : "",
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: birthdayGifters[i][k+2] ? birthdayGifters[i][k+2] : "",
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size: 90,
                type: WidthType.PERCENTAGE,
              },
            });

            longBCardChildren.push(gifterTable)

            bCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: longBCardChildren,
            })
          }
          
          // Create blob from doc
          var bCardDocBlob = await Packer.toBlob(bCardDoc)

          // Email blob to receiver
          // Get base64 of docx file
          // var bCardBase64Docx = await Packer.toBase64String(bCardDoc)

          let file = new File([bCardDocBlob], birthdayCardReceiver[i].fname + " Birthday Card.docx", {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"});

          let params = convertApi.createParams()
          params.add('file', file)
          let result = await convertApi.convert('docx', 'pdf', params)

          // Get result file URL
          let url = result.files[0].Url
          // Get the PDF data
          let response = await axios.get(url, {responseType: 'arraybuffer'});
          let pdfData = new Buffer.from(response.data, 'binary').toString('base64');

          const options = {
            from: `${this.state.settings.chapterName} <wishwell@onegiftfoundation.org>`,
            replyTo: this.state.settings.orgEmail,
            to: [birthdayCardReceiver[i].email],
            subject: SETTINGS.length > 0 ? ' Here\'s your ' + SETTINGS[0].chapterName + ' Birthday Card!' : 'Here\'s your WishWell Birthday Card!',
            html: `<p>${birthdayCardReceiver[i].fname},</p><p>Happy Birthday! A card from all of your friends is attached to this email :)</p><p>${birthdayCardReceiver[i].pcards ? 'You will be getting a physical card in the mail (if you haven\'t already), but let us know if you don\'t or would like to opt out of physical cards.' : ''}</p><p>And make sure to let us know if you\'d like to opt out of these digital cards in the future.</p><p><p>Happy Giving!</p><p>Your OneGift Team!</p><p><a href="https://www.onegiftfoundation.org" target="_blank" rel="noopener noreferrer">Learn more about WishWell and OneGift</a></p>`,
            attachments: [
              {
                filename: birthdayCardReceiver[i].fname + ' Birthday Card.pdf',
                content: pdfData,
                contentType: 'application/pdf',
                encoding: 'base64'
              }
            ]
          }
          try {
            // Send email call to api
            const apiName = 'wishwellapi';
            var path = '/wishwellapi/sendEmail';
            var myInit = { // OPTIONAL
                body: {
                  options: options
                },
                headers: {}, // OPTIONAL
            };
            await API.post(apiName, path, myInit)
  
          } catch (error) {
            Bugsnag.notify(error);
            toaster.danger('Members couldn\'t be emailed. Please try again or contact support', {duration: 5,})
          }

          // Add to ZIP
          var fileName = birthdayCardReceiver[i].lname + ", " + birthdayCardReceiver[i].fname + " - Birthday Card.docx"
          zip.file(fileName, bCardDocBlob, {binary: true})
        }

        // Anniversary Cards
        for (i = 1; i < anniversaryCardReceiver.length; i++) {
          const Rows = []
          // Setup new anniversary document
          const aCardDoc = new Document();

          const anniversaryBackgroundImage = Media.addImage(
            aCardDoc,
            Uint8Array.from(atob(anniversaryBackgroundBase64), c => c.charCodeAt(0)),
            700,
            1000,
            {
              floating: {
                horizontalPosition: {
                    offset: 360000, // relative: HorizontalPositionRelativeFrom.PAGE by default
                },
                verticalPosition: {
                    offset: 360000, // relative: VerticalPositionRelativeFrom.PAGE by default
                },
                behindDocument: true,
              },
            },
          );

          if (anniversaryGifters[i].length === 0) {
            //Don't need to generate doc if no gifters
            continue;
          }
          else if (anniversaryGifters[i].length <= 10) {
            
            for (j = 0; j < anniversaryGifters[i].length; j++) {
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 500,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: anniversaryGifters[i][j],
                          size: 72,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size: 60,
                type: WidthType.PERCENTAGE,
              },
            });

            aCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: [
                new Paragraph(anniversaryBackgroundImage),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    // anniversaryBackgroundImage,
                  ],
                }),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun({
                    text: year,
                    size: 50,
                    font: "Monotype Corsiva"
                  })]
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Happy Anniversary",
                      size: 120,
                      font: "Monotype Corsiva",
                    break: 0,}),
                    new TextRun({
                      // Anniversary card receiver and font
                      text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                      size: 100,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "Best wishes from your friends at",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: this.state.settings.chapterName,
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                  ]
                }),
                gifterTable,
              ],
            })
          }
          else if (anniversaryGifters[i].length <= 20) {
            
              for (j = 0; j < Math.ceil(anniversaryGifters[i].length / 2); j++) {
                k = j*2
                const Row = new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: anniversaryGifters[i][k],
                            size: 56,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: anniversaryGifters[i][k+1] ? anniversaryGifters[i][k+1] : "",
                            size: 56,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight,
                    rule: HeightRule.EXACT,
                  },
                })
                Rows.push(Row)
              }

              const gifterTable = new Table({
                alignment: AlignmentType.CENTER,
                rows: Rows,
                width: {
                  size: 80,
                  type: WidthType.PERCENTAGE,
                },
              });

              aCardDoc.addSection({
                margins: {
                  top: 720,
                  right: 720,
                  bottom: 720,
                  left: 720,
                },
                children: [
                  new Paragraph(anniversaryBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      // anniversaryBackgroundImage,
                    ],
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Anniversary",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "Best wishes from your friends at",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: this.state.settings.chapterName,
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  }),
                  gifterTable,
                ],
              })
          }
          else if (anniversaryGifters[i].length > 20) {
            var longACardChildren = [
              new Paragraph(anniversaryBackgroundImage),
              new Paragraph({
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [new TextRun({
                  text: year,
                  size: 50,
                  font: "Monotype Corsiva"
                })]
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Happy Anniversary",
                    size: 120,
                    font: "Monotype Corsiva",
                  break: 0,}),
                  new TextRun({
                    text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                    size: 100,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "Best wishes from your friends at",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: this.state.settings.chapterName,
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                ]
              }),
            ]
            
            for (j = 0; j < Math.ceil(anniversaryGifters[i].length / 3); j++) {
              k = j*3
              if ((j % 13 === 0) &&
                  j !== 0) {

                const gifterTable = new Table({
                  alignment: AlignmentType.CENTER,
                  rows: Rows,
                  width: {
                    size: 90,
                    type: WidthType.PERCENTAGE,
                  },
                });
                Rows.length = 0

                longACardChildren.push(
                  gifterTable,
                  new Paragraph({
                    pageBreakBefore: true,
                  }),
                  new Paragraph(anniversaryBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Anniversary",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "more friends...",
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  })
                )
              }
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: anniversaryGifters[i][k],
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: anniversaryGifters[i][k+1] ? anniversaryGifters[i][k+1] : "",
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: anniversaryGifters[i][k+2] ? anniversaryGifters[i][k+2] : "",
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size: 90,
                type: WidthType.PERCENTAGE,
              },
            });

            longACardChildren.push(gifterTable)

            aCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: longACardChildren,
            })
          }

          // Create blob from doc
          var aCardDocBlob = await Packer.toBlob(aCardDoc)

          // Email blob to receiver
          // Get base64 of docx file
          // var aCardBase64Docx = await Packer.toBase64String(aCardDoc)

          let file = new File([aCardDocBlob], birthdayCardReceiver[i].fname + " Anniversary Card.docx", {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"});

          let params = convertApi.createParams()
          params.add('file', file)
          let result = await convertApi.convert('docx', 'pdf', params)

          // Get result file URL
          let url = result.files[0].Url
          // Get the PDF data
          let response = await axios.get(url, {responseType: 'arraybuffer'});
          let pdfData = new Buffer.from(response.data, 'binary').toString('base64');

          const options = {
            from: `${this.state.settings.chapterName} <wishwell@onegiftfoundation.org>`,
            replyTo: this.state.settings.orgEmail,
            to: birthdayCardReceiver[i].email,
            subject: SETTINGS.length > 0 ? ' Here\'s your ' + SETTINGS[0].chapterName + ' Anniversary Card!' : 'Here\'s your WishWell Anniversary Card!',
            html: `<p>${birthdayCardReceiver[i].fname},</p><p>Happy Anniversary! A card from all of your friends is attached to this email :)</p><p>${birthdayCardReceiver[i].pcards ? 'You will be getting a physical card in the mail (if you haven\'t already), but let us know if you don\'t or would like to opt out of physical cards.' : ''}</p><p>And make sure to let us know if you\'d like to opt out of these digital cards in the future.</p><p><p>Happy Giving!</p><p>Your OneGift Team!</p>`,
            attachments: [
              {
                filename: birthdayCardReceiver[i].fname + ' Anniversary Card.pdf',
                content: pdfData,
                contentType: 'application/pdf',
                encoding: 'base64'
              }
            ]
          }

          // Send email call to api
          const apiName = 'wishwellapi';
          path = '/wishwellapi/sendEmail';
          myInit = { // OPTIONAL
              body: {
                options: options
              },
              headers: {}, // OPTIONAL
          };
          await API.post(apiName, path, myInit)

          // Add to ZIP
          fileName = anniversaryCardReceiver[i].lname + ", " + anniversaryCardReceiver[i].fname + " - Anniversary Card.docx"
          zip.file(fileName, aCardDocBlob, {binary: true})
        }

        // Generate ZIP file as blob for download
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          // saveAs from FileSaver will download the file
          FileSaver(content, zipFilename)
        })
      }
      this.setState({
        generateformssModalShown: false,
      })

      toaster.success('Cards emailed successfully! A zip file with all of the emailed cards has also been generated for your records.', {duration: 5,})
    }
    catch (error) {
        Bugsnag.notify(error);
        toaster.danger('Cards generator failed: ' + error + '. If issue continues, contact support.', {duration: 5,})
    }
  }

  GenerateManyCandE = async () => {
    try {
      // Get/set month and year for cards and envelopes
      const month = document.getElementById("month").value
      var monthLiteral = ''
      switch(month) {
        case '01':
          monthLiteral = 'January'
          break;
        case '02':
          monthLiteral = 'February'
          break;
        case '03':
          monthLiteral = 'March'
          break;
        case '04':
          monthLiteral = 'April'
          break;
        case '05':
          monthLiteral = 'May'
          break;
        case '06':
          monthLiteral = 'June'
          break;
        case '07':
          monthLiteral = 'July'
          break;
        case '08':
          monthLiteral = 'August'
          break;
        case '09':
          monthLiteral = 'September'
          break;
        case '10':
          monthLiteral = 'October'
          break;
        case '11':
          monthLiteral = 'November'
          break;
        case '12':
          monthLiteral = 'December'
          break;
        default:
          break;
      }
      const year = document.getElementById("year").value

      // Get members and orders
      var nextToken = null;
      var count = 0
      var filteredList
      var MEMBERS = []
      const user_info = await Auth.currentUserInfo()
      const username = user_info.username
      const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
      const orgId = user.data.userByUserName.items[0].organizationId
      let filter = {
          and: [{ pactive: { eq: "true"} },
                { DOB: { ne: "1900-01-01" } }
              ]
      };
      while (nextToken || count === 0) {
          count = 1
          filteredList = await API.graphql(
          graphqlOperation(membersByOrganizationId, {
            organizationId: orgId,
            filter: filter,
            limit: 900,
            nextToken:nextToken}))
          nextToken = filteredList.data.membersByOrganizationId.nextToken
          MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items)
      }

      MEMBERS.sort(function(a, b) {
        var textA = a.lname.toUpperCase();
        var textB = b.lname.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      nextToken = null;
      count = 0
      var filteredOrders
      var ORDERS = []
      let oFilter = {
        and: [{ year: { eq: year } }]
    };
      while (nextToken || count === 0) {
          count = 1
          filteredOrders = await API.graphql(
          graphqlOperation(ordersByOrganizationId, {
            organizationId: orgId,
            filter: oFilter,
            limit: 900,
            nextToken:nextToken}))
          nextToken = filteredOrders.data.ordersByOrganizationId.nextToken
          ORDERS = ORDERS.concat(filteredOrders.data.ordersByOrganizationId.items)
      }

      // SCRIPT: to update ORDERS
      // var ordersUpdated = 0
      // for (var i = 0; i < ORDERS.length; i++) {
      //   if (ORDERS[i].updatedAt > "2023-10") {
      //     ordersUpdated++
      //     var updatedOrder = {}
      //     updatedOrder.id = ORDERS[i].id
      //     updatedOrder.year = 2024
      //     await API.graphql(graphqlOperation(updateOrder, {input: updatedOrder}))
      //       .catch((err) => console.log(err))
      //     console.log(ordersUpdated +' orders updated: ' + ORDERS[i].id + ' ' + ORDERS[i].organizationId + ' ' + ORDERS[i].updatedAt + ' ' + ORDERS[i].year)
      //   }
      // }
      // console.log('All orders Updated!')
      // return

      
      // Get actual date of order
      for (var i = 0; i < ORDERS.length; i++) {
        // Updated at holds actual date record of order
        ORDERS[i].updatedAt = (new Date(ORDERS[i].createdAt.split('-')[0], ORDERS[i].createdAt.split('-')[1]-1))
      }

      // Create arrays of bday and anniversary receivers
      var birthdayCardReceiver = [[]]
      var anniversaryCardReceiver = [[]]
      birthdayCardReceiver[0][0] = ORDERS[1]
      for (i = 0; i < MEMBERS.length; i++) {
        if (MEMBERS[i].DOB.split('-')[1] === month && MEMBERS[i].pcards === true) {
              if (MEMBERS[i].ptype === 5) {
                anniversaryCardReceiver.push(MEMBERS[i])
              }
              else {
                birthdayCardReceiver.push(MEMBERS[i])
              }
        }
      }

      var date = new Date()

      // Create arrays with all gifter/donor pnumbers
      var birthdayGifters = []
      for (i = 0; i < birthdayCardReceiver.length; i++) {
        birthdayGifters[i] = []
        for (var m = 0; m < ORDERS.length; m++) {
          if (birthdayCardReceiver[i].pnumber === ORDERS[m].receiverPNumber &&
              (date - ORDERS[m].updatedAt)/(1000*60*60*24*30.5) < 12) {
                for (var n = 0; n < MEMBERS.length; n++) {
                  if (ORDERS[m].donorPNumber) {
                    if (MEMBERS[n].pnumber === ORDERS[m].donorPNumber) {
                      birthdayGifters[i].push(MEMBERS[n].fname + ' ' + MEMBERS[n].lname)
                    }
                  }
                  else {
                    ORDERS[m].donorName && birthdayGifters[i].push(ORDERS[m].donorName)
                  }
                }
          }
        }
      }

      for (i = 0; i < birthdayGifters.length; i++) {

        // Add padded members if less than 5 wishes
        if (birthdayGifters[i].length < 5 && this.state.settings.defaultWishers.length > 0) {
          const defaultWisherValues = this.state.settings.defaultWishers.map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`))
          const defaultWishers = defaultWisherValues.map(obj => obj.label)
          birthdayGifters[i].unshift(...defaultWishers)
        }
        birthdayGifters[i].sort(function(a, b) {
          var textA = a.split(" ")[a.split(" ").length - 1];
          var textB = b.split(" ")[b.split(" ").length - 1];
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }

      var anniversaryGifters = []
      for (i = 0; i < anniversaryCardReceiver.length; i++) {
        anniversaryGifters[i] = []
        for (m = 0; m < ORDERS.length; m++) {
          if (anniversaryCardReceiver[i].pnumber === ORDERS[m].receiverPNumber &&
              (date - ORDERS[m].updatedAt)/(1000*60*60*24*30.5) < 12) {
                for (n = 0; n < MEMBERS.length; n++) {
                  if (ORDERS[m].donorPNumber) {
                    if (MEMBERS[n].pnumber === ORDERS[m].donorPNumber) {
                      anniversaryGifters[i].push(MEMBERS[n].fname + ' ' + MEMBERS[n].lname)
                    }
                  }
                  else {
                    ORDERS[m].donorName && anniversaryGifters[i].push(ORDERS[m].donorName)
                  }
                }
          }
        }
      }

      for (i = 0; i < anniversaryGifters.length; i++) {

        // Add padded members if less than 5 wishes
        if (anniversaryGifters[i].length < 5 && this.state.settings.defaultWishers.length > 0) {
          const defaultWisherValues = this.state.settings.defaultWishers.map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`))
          const defaultWishers = defaultWisherValues.map(obj => obj.label)
          anniversaryGifters[i].unshift(...defaultWishers)
        }
        anniversaryGifters[i].sort(function(a, b) {
          var textA = a.split(" ")[a.split(" ").length - 1];
          var textB = b.split(" ")[b.split(" ").length - 1];
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }

      // create docs and download
      {
        const bCardDoc = new Document();
        const aCardDoc = new Document();
        const envDoc = new Document();

        const birthdayBackgroundImage = Media.addImage(
          bCardDoc,
          Uint8Array.from(atob(birthdayBackgroundBase64), c => c.charCodeAt(0)),
          700,
          1000,
          {
            floating: {
              horizontalPosition: {
                  offset: 360000, // relative: HorizontalPositionRelativeFrom.PAGE by default
              },
              verticalPosition: {
                  offset: 360000, // relative: VerticalPositionRelativeFrom.PAGE by default
              },
              behindDocument: true,
            },
          },
        );
        const anniversaryBackgroundImage = Media.addImage(
          aCardDoc,
          Uint8Array.from(atob(anniversaryBackgroundBase64), c => c.charCodeAt(0)),
          700,
          1000,
          {
            floating: {
              horizontalPosition: {
                  offset: 360000, // relative: HorizontalPositionRelativeFrom.PAGE by default
              },
              verticalPosition: {
                  offset: 360000, // relative: VerticalPositionRelativeFrom.PAGE by default
              },
              behindDocument: true,
            },
          },
        );
        const birthdayCardEnvelope = Media.addImage(
          envDoc,
          Uint8Array.from(atob(birthdayCardBackgroundBase64), c => c.charCodeAt(0)),
          1000,
          700,
          {
            floating: {
              horizontalPosition: {
                  offset: 360000, // relative: HorizontalPositionRelativeFrom.PAGE by default
              },
              verticalPosition: {
                  offset: 360000, // relative: VerticalPositionRelativeFrom.PAGE by default
              },
              behindDocument: true,
            },
          },
        );

        const anniversaryCardEnvelope = Media.addImage(
          envDoc,
          Uint8Array.from(atob(anniversaryCardBackgroundBase64), c => c.charCodeAt(0)),
          1000,
          700,
          {
            floating: {
              horizontalPosition: {
                  offset: 360000, // relative: HorizontalPositionRelativeFrom.PAGE by default
              },
              verticalPosition: {
                  offset: 360000, // relative: VerticalPositionRelativeFrom.PAGE by default
              },
              behindDocument: true,
            },
          },
        );
        
        // Set standard properties
        const borders = {
          top: {
              style: BorderStyle.NONE,
              size: 1,
          },
          bottom: {
              style: BorderStyle.NONE,
              size: 1,
          },
          left: {
              style: BorderStyle.NONE,
              size: 1,
          },
          right: {
              style: BorderStyle.NONE,
              size: 1,
          },
        };  

        // create array of pages and iterate through receivers
        // const cards = new Array()

        // Birthday cards
        for (i = 1; i < birthdayCardReceiver.length; i++) {
          const Rows = []

          // Can add in default donors
          // birthdayGifters[i].unshift("Ava Goldstein", "Jackie Deckenger", "Carol Bloom", "Judith Meyer", "Paula Cohen")

          // Decrease font size and increase cell height if any name is 20+ characters
          var tableHeight = 700;
          // const cellWidth = 3000;
          var fontSize = 45
          var rowCount = 10
          for (var j = 0; j < birthdayGifters[i].length; j++) {
            if (birthdayGifters[i][j].length > 20) {
              fontSize = 35
              tableHeight = 1000
              rowCount = 9
              break
            }
          }

          if (birthdayGifters[i].length > 0 &&
            birthdayGifters[i].length <= rowCount) {
            
            for (j = 0; j < birthdayGifters[i].length; j++) {
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                           text: birthdayGifters[i][j],
                           size: Math.round(fontSize * 1.6, 0),
                           font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size:90,
                type: WidthType.PERCENTAGE,
              },
            });
            //Rows.splice(0,Rows.length)

            bCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: [
                new Paragraph(birthdayBackgroundImage),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    // birthdayBackgroundImage,
                  ],
                }),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun({
                    text: year,
                    size: 50,
                    font: "Monotype Corsiva"
                  })]
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Happy Birthday",
                      size: 100,
                      font: "Monotype Corsiva",
                    break: 0,}),
                    new TextRun({
                      // Receiver name and font
                      text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                      size: 100,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "Best wishes from your friends at",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: this.state.settings.chapterName,
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                  ]
                }),
                gifterTable,
              ],
            })
          }
          else if (birthdayGifters[i].length > rowCount &&
            birthdayGifters[i].length <= rowCount*2) {
            
              for (j = 0; j < Math.ceil(birthdayGifters[i].length / 2); j++) {
                var k = j*2
                const Row = new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                             text: birthdayGifters[i][k],
                             size: Math.round(fontSize * 1.24, 0),
                             font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                             text: birthdayGifters[i][k+1] ? birthdayGifters[i][k+1] : "",
                             size: Math.round(fontSize * 1.24, 0),
                             font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight,
                    rule: HeightRule.EXACT,
                  },
                })
                Rows.push(Row)
              }

              const gifterTable = new Table({
                alignment: AlignmentType.CENTER,
                rows: Rows,
                width: {
                  size: 90,
                  type: WidthType.PERCENTAGE,
                },
              });
              //Rows.splice(0,Rows.length)

              bCardDoc.addSection({
                margins: {
                  top: 720,
                  right: 720,
                  bottom: 720,
                  left: 720,
                },
                children: [
                  new Paragraph(birthdayBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      // birthdayBackgroundImage,
                    ],
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Birthday",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "Best wishes from your friends at",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: this.state.settings.chapterName,
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  }),
                  gifterTable,
                ],
              })
          }
          else if (birthdayGifters[i].length > rowCount*2) {

            var longBCardChildren = [
              new Paragraph(birthdayBackgroundImage),
              new Paragraph({
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [new TextRun({
                  text: year,
                  size: 50,
                  font: "Monotype Corsiva"
                })]
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Happy Birthday",
                    size: 120,
                    font: "Monotype Corsiva",
                  break: 0,}),
                  new TextRun({
                    text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                    size: 100,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "Best wishes from your friends at",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: this.state.settings.chapterName,
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                ]
              }),
            ]

            for (j = 0; j < Math.ceil(birthdayGifters[i].length / 3); j++) {
              k = j*3
              if ((j % 13 === 0) &&
                  j !== 0) {

                const gifterTable = new Table({
                  alignment: AlignmentType.CENTER,
                  rows: Rows,
                  width: {
                    size: 90,
                    type: WidthType.PERCENTAGE,
                  },
                });
                Rows.length = 0

                longBCardChildren.push(
                  gifterTable,
                  new Paragraph({
                    pageBreakBefore: true,
                  }),
                  new Paragraph(birthdayBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Birthday",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "more friends...",
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  })
                )
              }
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: birthdayGifters[i][k],
                          size: Math.round(fontSize * .88, 0),
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: birthdayGifters[i][k+1] ? birthdayGifters[i][k+1] : "",
                          size: Math.round(fontSize * .88, 0),
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: birthdayGifters[i][k+2] ? birthdayGifters[i][k+2] : "",
                          size: Math.round(fontSize * .88, 0),
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
            });

            longBCardChildren.push(gifterTable)

            bCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: longBCardChildren,
            })
          }
        }

        // Anniversary Cards
        for (i = 1; i < anniversaryCardReceiver.length; i++) {
          const Rows = []

          // Can add in default donors
          // anniversaryGifters[i].unshift("Ava Goldstein", "Jackie Deckenger", "Carol Bloom", "Judith Meyer", "Paula Cohen")

          // Decrease font size and increase cell height if any name is 20+ characters
          tableHeight = 700;
          // const cellWidth = 3000;
          fontSize = 45
          rowCount = 10
          for (j = 0; j < anniversaryGifters[i].length; j++) {
            if (anniversaryGifters[i][j].length > 20) {
              fontSize = 35
              tableHeight = 1000
              rowCount = 9
              break
            }
          }

          if (anniversaryGifters[i].length > 0 &&
            anniversaryGifters[i].length <= rowCount) {
            
            for (j = 0; j < anniversaryGifters[i].length; j++) {
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                           text: anniversaryGifters[i][j],
                           size: Math.round(fontSize * 1.6, 0),
                           font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size: 90,
                type: WidthType.PERCENTAGE,
              },
            });

            aCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: [
                new Paragraph(anniversaryBackgroundImage),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    // anniversaryBackgroundImage,
                  ],
                }),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun({
                    text: year,
                    size: 50,
                    font: "Monotype Corsiva"
                  })]
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Happy Anniversary",
                      size: 120,
                      font: "Monotype Corsiva",
                    break: 0,}),
                    new TextRun({
                      // Anniversary card receiver and font
                      text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                      size: 100,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "Best wishes from your friends at",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: this.state.settings.chapterName,
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                  ]
                }),
                gifterTable,
              ],
            })
          }
          else if (anniversaryGifters[i].length > rowCount &&
            anniversaryGifters[i].length <= rowCount * 2) {
            
              for (j = 0; j < Math.ceil(anniversaryGifters[i].length / 2); j++) {
                k = j*2
                const Row = new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                             text: anniversaryGifters[i][k],
                             size: Math.round(fontSize * 1.24, 0),
                             font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                             text: anniversaryGifters[i][k+1] ? anniversaryGifters[i][k+1] : "",
                             size: Math.round(fontSize * 1.24, 0),
                             font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight,
                    rule: HeightRule.EXACT,
                  },
                })
                Rows.push(Row)
              }

              const gifterTable = new Table({
                alignment: AlignmentType.CENTER,
                rows: Rows,
                width: {
                  size: 90,
                  type: WidthType.PERCENTAGE,
                },
              });

              aCardDoc.addSection({
                margins: {
                  top: 720,
                  right: 720,
                  bottom: 720,
                  left: 720,
                },
                children: [
                  new Paragraph(anniversaryBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      // anniversaryBackgroundImage,
                    ],
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Anniversary",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "Best wishes from your friends at",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: this.state.settings.chapterName,
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  }),
                  gifterTable,
                ],
              })
          }
          else if (anniversaryGifters[i].length > rowCount * 2) {
            var longACardChildren = [
              new Paragraph(anniversaryBackgroundImage),
              new Paragraph({
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [new TextRun({
                  text: year,
                  size: 50,
                  font: "Monotype Corsiva"
                })]
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Happy Anniversary",
                    size: 120,
                    font: "Monotype Corsiva",
                  break: 0,}),
                  new TextRun({
                    text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                    size: 100,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "Best wishes from your friends at",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: this.state.settings.chapterName,
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                ]
              }),
            ]
            
            for (j = 0; j < Math.ceil(anniversaryGifters[i].length / 3); j++) {
              k = j*3
              if ((j % 13 === 0) &&
                  j !== 0) {

                const gifterTable = new Table({
                  alignment: AlignmentType.CENTER,
                  rows: Rows,
                  width: {
                    size: 90,
                    type: WidthType.PERCENTAGE,
                  },
                });
                Rows.length = 0

                longACardChildren.push(
                  gifterTable,
                  new Paragraph({
                    pageBreakBefore: true,
                  }),
                  new Paragraph(anniversaryBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Anniversary",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "more friends...",
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  })
                )
              }
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: anniversaryGifters[i][k],
                          size: Math.round(fontSize * .88, 0),
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: anniversaryGifters[i][k+1] ? anniversaryGifters[i][k+1] : "",
                          size: Math.round(fontSize * .88, 0),
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: anniversaryGifters[i][k+2] ? anniversaryGifters[i][k+2] : "",
                          size: Math.round(fontSize * .88, 0),
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
            });

            longACardChildren.push(gifterTable)

            aCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: longACardChildren,
            })
          }
        }

        // Get settings for return envelope
        var settings = await this.getSettings()

        // Create return address element for envelopes
        const returnAddress = new Table({
          alignment: AlignmentType.LEFT,
          VerticalPositionAlign: VerticalPositionAlign.TOP,
          VerticalAlign: VerticalAlign.TOP,
          width: {
            size: 35,
            type: WidthType.PERCENTAGE,
          },
          rows: [
            new TableRow({
              children: [
                  new TableCell({
                    borders,
                    width: {
                      size: 100,
                      type: WidthType.DXA
                    },
                    children: [new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                        text: "     " + settings.envelopeReturnName === undefined ? '' : settings.envelopeReturnName + ' ',
                        size: 32,
                        font: "Monotype Corsiva",
                        }),
                      ]
                    })],
                  }),
              ],
              height: {
                rule: HeightRule.AUTO
              },
            }),
            new TableRow({
              children: [
                  new TableCell({
                    borders,
                    width: {
                      size: 100,
                      type: WidthType.DXA
                    },
                    children: [new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                        text: "     " + settings.envelopeReturnAddressLine1 === undefined ? '' : settings.envelopeReturnAddressLine1,
                        size: 20,
                        font: "Copperplate Bold",
                        }),
                      ]
                    })],
                  }),
              ],
              height: {
                rule: HeightRule.AUTO
              },
            }),
            new TableRow({
              children: [
                  new TableCell({
                    borders,
                    width: {
                      size: 100,
                      type: WidthType.DXA
                    },
                    children: [new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                        text: "     " + settings.envelopeReturnAddressLine2 === undefined ? '' : settings.envelopeReturnAddressLine2,
                        size: 20,
                        font: "Copperplate Bold",
                        }),
                      ]
                    })],
                  }),
              ],
              height: {
                rule: HeightRule.AUTO
              },
            })
          ]
        })

        // Add Envelopes
        for (i = 1; i < birthdayCardReceiver.length; i++) {
          // only generate envelope if receiver has gifters
          if (birthdayGifters[i].length > 0) {
            const cardTable = new Table({
              alignment: AlignmentType.CENTER,
              VerticalPositionAlign: VerticalAlign.CENTER,
              VerticalAlign: VerticalAlign.CENTER,
              width: {
                size: 60,
                type: WidthType.PERCENTAGE,
              },
              rows: [
                new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 300,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: "     " + birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                            size: 72,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight + 300,
                    rule: HeightRule.EXACT,
                  },
                }),
                new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 300,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: "     " + birthdayCardReceiver[i].address1.split(',')[0],
                            size: 48,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight,
                    rule: HeightRule.EXACT,
                  },
                }),
                new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 300,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: "     " + birthdayCardReceiver[i].address1.substring(birthdayCardReceiver[i].address1.indexOf(',') + 2),
                            size: 48,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight,
                    rule: HeightRule.EXACT,
                  },
                })
              ]
            })
            
            envDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              size: {
                orientation: PageOrientation.LANDSCAPE,
              },
              children: [
                new Paragraph(birthdayCardEnvelope),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: "",
                      size: 84,
                      font: "Monotype Corsiva",
                      break: 1,
                    }),
                  ]
                }),
                returnAddress,
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [new TextRun({
                    text: "                                                                                                                             " + birthdayCardReceiver[i].DOB.split("-")[1] + "/" + birthdayCardReceiver[i].DOB.split("-")[2],
                    size: 30,
                    font: "Monotype Corsiva"
                  })]
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "",
                      size: 120,
                      font: "Monotype Corsiva",
                      break: 2,
                    }),
                  ]
                }),
                cardTable
              ]
            })
          }
        }
        for (i = 1; i < anniversaryCardReceiver.length; i++) {
          // only generate envelope if receiver has gifters
          if (anniversaryGifters[i].length > 0) {
            const cardTable = new Table({
              alignment: AlignmentType.CENTER,
              VerticalPositionAlign: VerticalAlign.CENTER,
              VerticalAlign: VerticalAlign.CENTER,
              width: {
                size: 60,
                type: WidthType.PERCENTAGE,
              },
              rows: [
                new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 300,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: "     " + anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                            size: 72,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight + 300,
                    rule: HeightRule.EXACT,
                  },
                }),
                new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 300,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: "     " + anniversaryCardReceiver[i].address1.split(',')[0],
                            size: 48,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight,
                    rule: HeightRule.EXACT,
                  },
                }),
                new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 300,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: "     " + anniversaryCardReceiver[i].address1.substring(anniversaryCardReceiver[i].address1.indexOf(',') + 2),
                            size: 48,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight,
                    rule: HeightRule.EXACT,
                  },
                })
              ]
            })
            
            envDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              size: {
                orientation: PageOrientation.LANDSCAPE,
              },
              children: [
                new Paragraph(anniversaryCardEnvelope),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: "",
                      size: 84,
                      font: "Monotype Corsiva",
                      break: 1,
                    }),
                  ]
                }),
                returnAddress,
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [new TextRun({
                    text: "                                                                                                                             " + anniversaryCardReceiver[i].DOB.split("-")[1] + "/" + anniversaryCardReceiver[i].DOB.split("-")[2],
                    size: 30,
                    font: "Monotype Corsiva"
                  })]
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "",
                      size: 120,
                      font: "Monotype Corsiva",
                      break: 2,
                    }),
                  ]
                }),
                cardTable,
              ],
            })
          }
        }

        // Print birthday and anniversary cards and envelopes
        Packer.toBlob(bCardDoc).then((blob) => {
          // saveAs from FileSaver will download the file
          FileSaver(blob, monthLiteral + " Birthday WishWell Cards.docx");
        });
        Packer.toBlob(aCardDoc).then((blob) => {
          // saveAs from FileSaver will download the file
          FileSaver(blob, monthLiteral + " Anniversary WishWell Cards.docx");
        });
        Packer.toBlob(envDoc).then((blob) => {
          // saveAs from FileSaver will download the file
          FileSaver(blob, monthLiteral + " WishWell Envelopes.docx");
        });
      }
      toaster.success('Cards and envelopes generated successfully!', {duration: 5,})
    }
    catch (error) {
        Bugsnag.notify(error);
        toaster.danger('Card and envelopes generator failed: ' + error + '. If issue continues, contact support.', {duration: 5,})
    }
  }

  GenerateIndividualCandE = async () => {
    try {
      // Get/set month and year for cards
      const month = document.getElementById("month").value
      var monthLiteral = ''
      switch(month) {
        case '01':
          monthLiteral = 'January'
          break;
        case '02':
          monthLiteral = 'February'
          break;
        case '03':
          monthLiteral = 'March'
          break;
        case '04':
          monthLiteral = 'April'
          break;
        case '05':
          monthLiteral = 'May'
          break;
        case '06':
          monthLiteral = 'June'
          break;
        case '07':
          monthLiteral = 'July'
          break;
        case '08':
          monthLiteral = 'August'
          break;
        case '09':
          monthLiteral = 'September'
          break;
        case '10':
          monthLiteral = 'October'
          break;
        case '11':
          monthLiteral = 'November'
          break;
        case '12':
          monthLiteral = 'December'
          break;
        default:
          break;
      }
      const year = document.getElementById("year").value

      // Get members and orders
      var nextToken = null;
      var count = 0
      var filteredList
      var MEMBERS = []
      const user_info = await Auth.currentUserInfo()
      const username = user_info.username
      const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
      const orgId = user.data.userByUserName.items[0].organizationId
      let filter = {
          and: [{ pactive: { eq: "true"} },
                { DOB: { ne: "1900-01-01" } }]
      };
      while (nextToken || count === 0) {
          count = 1
          filteredList = await API.graphql(
          graphqlOperation(membersByOrganizationId, {
            organizationId: orgId,
            filter: filter,
            limit: 900,
            nextToken:nextToken}))
          nextToken = filteredList.data.membersByOrganizationId.nextToken
          MEMBERS = MEMBERS.concat(filteredList.data.membersByOrganizationId.items)
      }

      MEMBERS.sort(function(a, b) {
        var textA = a.lname.toUpperCase();
        var textB = b.lname.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      nextToken = null;
      count = 0
      var filteredOrders
      var ORDERS = []
      let oFilter = {
        and: [{ year: { eq: year} }]
      };
      while (nextToken || count === 0) {
          count = 1
          filteredOrders = await API.graphql(
          graphqlOperation(ordersByOrganizationId, {
            organizationId: orgId,
            filter: oFilter,
            limit: 900,
            nextToken:nextToken}))
          nextToken = filteredOrders.data.ordersByOrganizationId.nextToken
          ORDERS = ORDERS.concat(filteredOrders.data.ordersByOrganizationId.items)
      }
      
      // Get actual date of order
      for (var i = 0; i < ORDERS.length; i++) {
        // Updated at holds actual date record of order
        ORDERS[i].updatedAt = (new Date(ORDERS[i].createdAt.split('-')[0], ORDERS[i].createdAt.split('-')[1]-1))
      }

      // Create arrays of bday and anniversary receivers
      var birthdayCardReceiver = [[]]
      var anniversaryCardReceiver = [[]]
      birthdayCardReceiver[0][0] = ORDERS[1]
      for (i = 0; i < MEMBERS.length; i++) {
        if (MEMBERS[i].DOB.split('-')[1] === month && MEMBERS[i].pcards === true) {
              if (MEMBERS[i].ptype === 5) {
                anniversaryCardReceiver.push(MEMBERS[i])
              }
              else {
                birthdayCardReceiver.push(MEMBERS[i])
              }
        }
      }

      var date = new Date()

      // Create arrays with all gifter/donor pnumbers
      var birthdayGifters = []
      for (i = 0; i < birthdayCardReceiver.length; i++) {
        birthdayGifters[i] = []
        for (var m = 0; m < ORDERS.length; m++) {
          if (birthdayCardReceiver[i].pnumber === ORDERS[m].receiverPNumber &&
              (date - ORDERS[m].updatedAt)/(1000*60*60*24*30.5) < 12) {
                for (var n = 0; n < MEMBERS.length; n++) {
                  if (ORDERS[m].donorPNumber) {
                    if (MEMBERS[n].pnumber === ORDERS[m].donorPNumber) {
                      birthdayGifters[i].push(MEMBERS[n].fname + ' ' + MEMBERS[n].lname)
                    }
                  }
                  else {
                    ORDERS[m].donorName && birthdayGifters[i].push(ORDERS[m].donorName)
                  }
                }
          }
        }
      }

      for (i = 0; i < birthdayGifters.length; i++) {

        // Add padded members if less than 5 wishes
        if (birthdayGifters[i].length < 5 && this.state.settings.defaultWishers.length > 0) {
          const defaultWisherValues = this.state.settings.defaultWishers.map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`))
          const defaultWishers = defaultWisherValues.map(obj => obj.label)
          birthdayGifters[i].unshift(...defaultWishers)
        }
        birthdayGifters[i].sort(function(a, b) {
          var textA = a.split(" ")[a.split(" ").length - 1];
          var textB = b.split(" ")[b.split(" ").length - 1];
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }

      var anniversaryGifters = []
      for (i = 0; i < anniversaryCardReceiver.length; i++) {
        anniversaryGifters[i] = []
        for (m = 0; m < ORDERS.length; m++) {
          if (anniversaryCardReceiver[i].pnumber === ORDERS[m].receiverPNumber &&
              (date - ORDERS[m].updatedAt)/(1000*60*60*24*30.5) < 12) {
                for (n = 0; n < MEMBERS.length; n++) {
                  if (ORDERS[m].donorPNumber) {
                    if (MEMBERS[n].pnumber === ORDERS[m].donorPNumber) {
                      anniversaryGifters[i].push(MEMBERS[n].fname + ' ' + MEMBERS[n].lname)
                    }
                  }
                  else {
                    ORDERS[m].donorName && anniversaryGifters[i].push(ORDERS[m].donorName)
                  }
                }
          }
        }
      }

      for (i = 0; i < anniversaryGifters.length; i++) {

        // Add padded members if less than 5 wishes
        if (anniversaryGifters[i].length < 5 && this.state.settings.defaultWishers.length > 0) {
          const defaultWisherValues = this.state.settings.defaultWishers.map(str => JSON.parse(`{${str.replace("{", "").replace("}", "").replace(/(\w+)\s*=\s*([^,}]+)/g, '"$1":"$2"')}}`))
          const defaultWishers = defaultWisherValues.map(obj => obj.label)
          anniversaryGifters[i].unshift(...defaultWishers)
        }
        anniversaryGifters[i].sort(function(a, b) {
          var textA = a.split(" ")[a.split(" ").length - 1];
          var textB = b.split(" ")[b.split(" ").length - 1];
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }

      // create docs and download
      {
        var zip = new JSZip()
        const zipFilename = monthLiteral + ' ' + year.toString() + ' - PAP Cards.zip'
        
        // Set standard properties
        const borders = {
          top: {
              style: BorderStyle.NONE,
              size: 1,
          },
          bottom: {
              style: BorderStyle.NONE,
              size: 1,
          },
          left: {
              style: BorderStyle.NONE,
              size: 1,
          },
          right: {
              style: BorderStyle.NONE,
              size: 1,
          },
        };  
        const tableHeight = 700;
        // const cellWidth = 3000;

        // create array of pages and iterate through receivers
        // const cards = new Array()

        // Birthday cards
        for (i = 1; i < birthdayCardReceiver.length; i++) {
          const Rows = []
          // Setup new birthday document
          const bCardDoc = new Document();

          // Setup background image
          const birthdayBackgroundImage = Media.addImage(
            bCardDoc,
            Uint8Array.from(atob(birthdayBackgroundBase64), c => c.charCodeAt(0)),
            700,
            1000,
            {
              floating: {
                horizontalPosition: {
                    offset: 360000, // relative: HorizontalPositionRelativeFrom.PAGE by default
                },
                verticalPosition: {
                    offset: 360000, // relative: VerticalPositionRelativeFrom.PAGE by default
                },
                behindDocument: true,
              },
            },
          );

          // Can add in default donors
          // birthdayGifters[i].unshift("Ava Goldstein", "Jackie Deckenger", "Carol Bloom", "Judith Meyer", "Paula Cohen")

          if (birthdayGifters[i].length === 0) {
            //Don't need to generate doc if no gifters
            continue;
          }
          else if (birthdayGifters[i].length <= 10) {
            
            for (var j = 0; j < birthdayGifters[i].length; j++) {
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 500,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: birthdayGifters[i][j],
                          size: 72,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size: 60,
                type: WidthType.PERCENTAGE,
              },
            });
            //Rows.splice(0,Rows.length)

            bCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: [
                new Paragraph(birthdayBackgroundImage),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    // birthdayBackgroundImage,
                  ],
                }),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun({
                    text: year,
                    size: 50,
                    font: "Monotype Corsiva"
                  })]
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Happy Birthday",
                      size: 120,
                      font: "Monotype Corsiva",
                    break: 0,}),
                    new TextRun({
                      // Receiver name and font
                      text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                      size: 100,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "Best wishes from your friends at",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: this.state.settings.chapterName,
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                  ]
                }),
                gifterTable,
              ],
            })
          }
          else if (birthdayGifters[i].length <= 20) {
            
              for (j = 0; j < Math.ceil(birthdayGifters[i].length / 2); j++) {
                var k = j*2
                const Row = new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: birthdayGifters[i][k],
                            size: 56,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: birthdayGifters[i][k+1] ? birthdayGifters[i][k+1] : "",
                            size: 56,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight,
                    rule: HeightRule.EXACT,
                  },
                })
                Rows.push(Row)
              }

              const gifterTable = new Table({
                alignment: AlignmentType.CENTER,
                rows: Rows,
                width: {
                  size: 80,
                  type: WidthType.PERCENTAGE,
                },
              });
              //Rows.splice(0,Rows.length)

              bCardDoc.addSection({
                margins: {
                  top: 720,
                  right: 720,
                  bottom: 720,
                  left: 720,
                },
                children: [
                  new Paragraph(birthdayBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      // birthdayBackgroundImage,
                    ],
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Birthday",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "Best wishes from your friends at",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: this.state.settings.chapterName,
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  }),
                  gifterTable,
                ],
              })
          }
          else if (birthdayGifters[i].length > 20) {

            var longBCardChildren = [
              new Paragraph(birthdayBackgroundImage),
              new Paragraph({
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [new TextRun({
                  text: year,
                  size: 50,
                  font: "Monotype Corsiva"
                })]
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Happy Birthday",
                    size: 120,
                    font: "Monotype Corsiva",
                  break: 0,}),
                  new TextRun({
                    text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                    size: 100,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "Best wishes from your friends at",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: this.state.settings.chapterName,
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                ]
              }),
            ]
            
            for (j = 0; j < Math.ceil(birthdayGifters[i].length / 3); j++) {
              k = j*3
              if ((j % 13 === 0) &&
                  j !== 0) {

                const gifterTable = new Table({
                  alignment: AlignmentType.CENTER,
                  rows: Rows,
                  width: {
                    size: 90,
                    type: WidthType.PERCENTAGE,
                  },
                });
                Rows.length = 0

                longBCardChildren.push(
                  gifterTable,
                  new Paragraph({
                    pageBreakBefore: true,
                  }),
                  new Paragraph(birthdayBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Birthday",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: birthdayCardReceiver[i].fname + ' ' + birthdayCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "more friends...",
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  })
                )
              }
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: birthdayGifters[i][k],
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: birthdayGifters[i][k+1] ? birthdayGifters[i][k+1] : "",
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: birthdayGifters[i][k+2] ? birthdayGifters[i][k+2] : "",
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size: 90,
                type: WidthType.PERCENTAGE,
              },
            });

            longBCardChildren.push(gifterTable)

            bCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: longBCardChildren,
            })
          }
          
          // Create blob from doc
          var bCardDocBlob = await Packer.toBlob(bCardDoc)

          // Add to ZIP
          var fileName = birthdayCardReceiver[i].lname + ", " + birthdayCardReceiver[i].fname + " - Birthday Card.docx"
          zip.file(fileName, bCardDocBlob, {binary: true})
        }

        // Anniversary Cards
        for (i = 1; i < anniversaryCardReceiver.length; i++) {
          const Rows = []
          // Setup new anniversary document
          const aCardDoc = new Document();

          const anniversaryBackgroundImage = Media.addImage(
            aCardDoc,
            Uint8Array.from(atob(anniversaryBackgroundBase64), c => c.charCodeAt(0)),
            700,
            1000,
            {
              floating: {
                horizontalPosition: {
                    offset: 360000, // relative: HorizontalPositionRelativeFrom.PAGE by default
                },
                verticalPosition: {
                    offset: 360000, // relative: VerticalPositionRelativeFrom.PAGE by default
                },
                behindDocument: true,
              },
            },
          );

          // Can add in default donors
          // anniversaryGifters[i].unshift("Ava Goldstein", "Jackie Deckenger", "Carol Bloom", "Judith Meyer", "Paula Cohen")

          if (anniversaryGifters[i].length === 0) {
            //Don't need to generate doc if no gifters
            continue;
          }
          else if (anniversaryGifters[i].length <= 10) {
            
            for (j = 0; j < anniversaryGifters[i].length; j++) {
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 500,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: anniversaryGifters[i][j],
                          size: 72,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size: 60,
                type: WidthType.PERCENTAGE,
              },
            });

            aCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: [
                new Paragraph(anniversaryBackgroundImage),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    // anniversaryBackgroundImage,
                  ],
                }),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun({
                    text: year,
                    size: 50,
                    font: "Monotype Corsiva"
                  })]
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Happy Anniversary",
                      size: 120,
                      font: "Monotype Corsiva",
                    break: 0,}),
                    new TextRun({
                      // Anniversary card receiver and font
                      text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                      size: 100,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "Best wishes from your friends at",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: this.state.settings.chapterName,
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                    new TextRun({
                      text: "",
                      size: 50,
                      font: "Monotype Corsiva",
                    break: 1,}),
                  ]
                }),
                gifterTable,
              ],
            })
          }
          else if (anniversaryGifters[i].length <= 20) {
            
              for (j = 0; j < Math.ceil(anniversaryGifters[i].length / 2); j++) {
                k = j*2
                const Row = new TableRow({
                  children: [
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: anniversaryGifters[i][k],
                            size: 56,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                      new TableCell({
                        borders,
                        width: {
                          size: 700,
                          type: WidthType.DXA
                        },
                        children: [new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                            text: anniversaryGifters[i][k+1] ? anniversaryGifters[i][k+1] : "",
                            size: 56,
                            font: "Monotype Corsiva",
                            }),
                          ]
                        })],
                      }),
                  ],
                  height: {
                    height: tableHeight,
                    rule: HeightRule.EXACT,
                  },
                })
                Rows.push(Row)
              }

              const gifterTable = new Table({
                alignment: AlignmentType.CENTER,
                rows: Rows,
                width: {
                  size: 80,
                  type: WidthType.PERCENTAGE,
                },
              });

              aCardDoc.addSection({
                margins: {
                  top: 720,
                  right: 720,
                  bottom: 720,
                  left: 720,
                },
                children: [
                  new Paragraph(anniversaryBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      // anniversaryBackgroundImage,
                    ],
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Anniversary",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "Best wishes from your friends at",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: this.state.settings.chapterName,
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  }),
                  gifterTable,
                ],
              })
          }
          else if (anniversaryGifters[i].length > 20) {
            var longACardChildren = [
              new Paragraph(anniversaryBackgroundImage),
              new Paragraph({
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [new TextRun({
                  text: year,
                  size: 50,
                  font: "Monotype Corsiva"
                })]
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Happy Anniversary",
                    size: 120,
                    font: "Monotype Corsiva",
                  break: 0,}),
                  new TextRun({
                    text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                    size: 100,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "Best wishes from your friends at",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: this.state.settings.chapterName,
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                  new TextRun({
                    text: "",
                    size: 50,
                    font: "Monotype Corsiva",
                  break: 1,}),
                ]
              }),
            ]
            
            for (j = 0; j < Math.ceil(anniversaryGifters[i].length / 3); j++) {
              k = j*3
              if ((j % 13 === 0) &&
                  j !== 0) {

                const gifterTable = new Table({
                  alignment: AlignmentType.CENTER,
                  rows: Rows,
                  width: {
                    size: 90,
                    type: WidthType.PERCENTAGE,
                  },
                });
                Rows.length = 0

                longACardChildren.push(
                  gifterTable,
                  new Paragraph({
                    pageBreakBefore: true,
                  }),
                  new Paragraph(anniversaryBackgroundImage),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [new TextRun({
                      text: year,
                      size: 50,
                      font: "Monotype Corsiva"
                    })]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "Happy Anniversary",
                        size: 120,
                        font: "Monotype Corsiva",
                      break: 0,}),
                      new TextRun({
                        text: anniversaryCardReceiver[i].fname + ' ' + anniversaryCardReceiver[i].lname,
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "more friends...",
                        size: 100,
                        font: "Monotype Corsiva",
                      break: 1,}),
                      new TextRun({
                        text: "",
                        size: 50,
                        font: "Monotype Corsiva",
                      break: 1,}),
                    ]
                  })
                )
              }
              const Row = new TableRow({
                children: [
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: anniversaryGifters[i][k],
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: anniversaryGifters[i][k+1] ? anniversaryGifters[i][k+1] : "",
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                    new TableCell({
                      borders,
                      width: {
                        size: 700,
                        type: WidthType.DXA
                      },
                      children: [new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                          text: anniversaryGifters[i][k+2] ? anniversaryGifters[i][k+2] : "",
                          size: 45,
                          font: "Monotype Corsiva",
                          }),
                        ]
                      })],
                    }),
                ],
                height: {
                  height: tableHeight,
                  rule: HeightRule.EXACT,
                },
              })
              Rows.push(Row)
            }

            const gifterTable = new Table({
              alignment: AlignmentType.CENTER,
              rows: Rows,
              width: {
                size: 90,
                type: WidthType.PERCENTAGE,
              },
            });

            longACardChildren.push(gifterTable)

            aCardDoc.addSection({
              margins: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
              children: longACardChildren,
            })
          }

          // Create blob from doc
          var aCardDocBlob = await Packer.toBlob(aCardDoc)
          // Add to ZIP
          fileName = anniversaryCardReceiver[i].lname + ", " + anniversaryCardReceiver[i].fname + " - Anniversary Card.docx"
          zip.file(fileName, aCardDocBlob, {binary: true})
        }

        // Generate ZIP file as blob for download
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          // saveAs from FileSaver will download the file
          FileSaver(content, zipFilename)
        })
      }
      this.setState({
        generateformssModalShown: false,
      })

      toaster.success('Cards generated successfully!', {duration: 5,})
  }
  catch (error) {
      Bugsnag.notify(error);
      toaster.danger('Cards generator failed: ' + error + '. If issue continues, contact support.', {duration: 5,})
  }
  }

  changeState = async (Page) => {
    if (Page === "AddMember") {
      this.state.Activity = "Main"
      const newMember = {
        id: null,
        pnumber: null,
        mnumber: null,
        ptype: "",
        pactive: true,
        eforms: true,
        pforms: true,
        fname: "",
        lname: "",
        email: "",
        phone: null,
        address: "",
        addressRemote: "",
        DOB: "",
        anumber: null,
        apayment: "",
        notes: "",
        searchField: ""
      }
      this.setState({
        Activity: "MemberDetails",
        member: newMember,
        state: "AddMember",
      })
      this.forceUpdate()
    }
    else if (Page === "MemberDetails") {
      // Tracking
      mixpanel.track('Member details page visited');

      if (this.state.memberSelected === "") {
        toaster.warning("Please select a member first.", {duration: 5,})
      }
      else {
        this.setState({ 
          Activity: "Main",
          memberDetailsLoading: true
        })
        // Get pnumber from autocomplete component - it will be the number surrounded by the last set of parentheses
        const lastIndex = this.state.memberSelected.lastIndexOf('(');
        const pNumber = this.state.memberSelected.substring(lastIndex + 1).split(')')[0];
        var memberDetails
        var MEMBER = []
        try {
            const user_info = await Auth.currentUserInfo()
            const username = user_info.username

            // Set identity
            Bugsnag.setUser(username)
            
            const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
            const orgId = user.data.userByUserName.items[0].organizationId
            let filter = {
                and: [{ pnumber: { eq: pNumber} }]
            };
            var nextToken = null;
            var count = 0
            var filteredList
            
            //TODO: change to get query rather than fliterd list query
            while (nextToken || count === 0) {
                count = 1
                filteredList = await API.graphql(
                graphqlOperation(membersByOrganizationId, {
                  organizationId: orgId,
                  filter: filter,
                  limit: 900,
                  nextToken:nextToken
                }))
                nextToken = filteredList.data.membersByOrganizationId.nextToken
                MEMBER = MEMBER.concat(filteredList.data.membersByOrganizationId.items)
            }
            memberDetails = MEMBER[0]
        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }
        memberDetails && this.setState({
          member: {
            id: memberDetails.id,
            pnumber: memberDetails.pnumber,
            mnumber: memberDetails.mnumber,
            ptype: memberDetails.ptype,
            pactive: memberDetails.pactive,
            eforms: memberDetails.eforms,
            pforms: memberDetails.pforms,
            ecards: memberDetails.ecards,
            pcards: memberDetails.pcards,
            birthdayBooster: memberDetails.birthdayBooster,
            eReminders: memberDetails.eReminders,
            fname: memberDetails.fname,
            lname: memberDetails.lname,
            email: memberDetails.email,
            phone: memberDetails.phone,
            address: memberDetails.address1,
            addressRemote: memberDetails.address2,
            DOB: memberDetails.DOB,
            anumber: memberDetails.anumber,
            apayment: memberDetails.apayment,
            notes: memberDetails.notes,
            searchField: memberDetails.searchField
          },
        })
        this.setState ({
          Activity: Page,
          memberDetailsLoading: false,
          state: "MemberDetails",
        })
      }
    }
    else if (Page === "OrderForm") {
      this.setState({
        Activity: Page,
        member: {},
        state: Page,
      })
    }
    else {
      this.setState({
        Activity: Page,
        member: {},
        state: Page,
      })
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
          <img src={logo} alt="WishWell by OneGift" style={{ textAlign: "center", paddingTop: '100px', width: '30%', paddingBottom: '20px' }} />
          <div className="progress-bar">
              <div className="progress-bar-fill"></div>
          </div>
        </Pane>
      )
    }
    else {
      // Feature announcements
      const announcements = [
        // Last announcement posted: announcement_3
        // {
        //   id: 'announcement_2',
        //   message: "Credit card processing is now available!",
        //   url: 'https://onegiftfoundation.org/resources/how-to-make-well-wishes-online'
        // },
        // {
        //   id: 'announcement_3',
        //   message: "WishWell now works on tablets and phones!",
        //   url: 'https://onegiftfoundation.org/resources/'
        // },
        // Add more announcements here as needed
      ];
      if (this.state.Activity === "Main") {
        return (
          <div>
            <NPS/>
            <Notification announcements={announcements} />
            {this.navBar()}
            <img src={this.state.settings.logo[1]} alt={this.state.settings.logo[0]} style={{ textAlign: "center", paddingTop: '100px', width: '50%' }}></img>
            {/* <h2 style={{ textAlign: "center" }}>PAP Mailers 1.0</h2> */}

            <div>
              <footer style={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: 'center',
                  alignItems: "center",
                  backgroundColor: '#0a003a',
                  position: "fixed",
                  bottom: "0px",
                  height: "50px",
                  width: "100%",
                  zIndex: 100, 
                  color: 'white'
                }}>
                  <p><a href="https://onegiftfoundation.org/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>WishWell was created by The OneGift Foundation</a> | <a href="https://onegiftfoundation.org/the-legal-stuff" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Legal Terms</a></p>
                </footer>
            </div>
          </div>
        )
      }
      if (this.state.Activity === "MemberDetails") {
        return (
          <div style={{ marginTop: '100px' }}>
          <NPS/>
          <Notification announcements={announcements} />
          {this.navBar()}
          <div style={{ textAlign: "center" }}></div>
            <MemberDetails 
              id={this.state.member.id}
              pnumber={this.state.member.pnumber}
              mnumber={this.state.member.mnumber}
              ptype={this.state.member.ptype}
              pactive={this.state.member.pactive}
              pforms={this.state.member.pforms}
              eforms={this.state.member.eforms}
              pcards={this.state.member.pcards}
              ecards={this.state.member.ecards}
              birthdayBooster={this.state.member.birthdayBooster}
              eReminders={this.state.member.eReminders}
              fname={this.state.member.fname}
              lname={this.state.member.lname}
              email={this.state.member.email}
              phone={this.state.member.phone}
              address={this.state.member.address}
              addressRemote={this.state.member.addressRemote}
              DOB={this.state.member.DOB}
              anumber={this.state.member.anumber}
              apayment={this.state.member.apayment}
              notes={this.state.member.notes}
              changePage={this.changeState.bind(this)}
              reloadMain={this.loadPage}
            />
            <br></br>

            <div>
              <footer style={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: 'center',
                  alignItems: "center",
                  backgroundColor: '#0a003a',
                  position: "fixed",
                  bottom: "0px",
                  height: "50px",
                  width: "100%",
                  zIndex: 100,
                }}>
                <p><a href="https://onegiftfoundation.org/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>WishWell was created by The OneGift Foundation</a> | <a href="https://onegiftfoundation.org/the-legal-stuff" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Legal Terms</a></p>
              </footer>
            </div>
          </div>
        )
      }
      if (this.state.Activity === "OrderForm") {
        return (
          <div style={{ marginTop: '100px' }}>
            <NPS/>
            <Notification announcements={announcements} />
            {this.navBar()}
            <OrderForm />
            <br></br>
            
            <div>
              <footer style={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: 'center',
                  alignItems: "center",
                  backgroundColor: '#0a003a',
                  position: "fixed",
                  bottom: "0px",
                  height: "50px",
                  width: "100%",
                  zIndex: 100, 
                  color: 'white'
                }}>
                  <p><a href="https://onegiftfoundation.org/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>WishWell was created by The OneGift Foundation</a> | <a href="https://onegiftfoundation.org/the-legal-stuff" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Legal Terms</a></p>
                </footer>
            </div>
          </div>
        )
      }
      if (this.state.Activity === "Reports") {
        return (
          <div style={{ marginTop: '100px' }}>
            <NPS/>
            <Notification announcements={announcements} />
            {this.navBar()}
            <Reports />

            <div>
              <footer style={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: 'center',
                  alignItems: "center",
                  backgroundColor: '#0a003a',
                  position: "fixed",
                  bottom: "0px",
                  height: "50px",
                  width: "100%",
                  zIndex: 100, 
                  color: 'white'
                }}>
                  <p><a href="https://onegiftfoundation.org/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>WishWell was created by The OneGift Foundation</a> | <a href="https://onegiftfoundation.org/the-legal-stuff" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Legal Terms</a></p>
                </footer>
            </div>
          </div>
        )
      }
      if (this.state.Activity === "Tools") {
        return (
          <div style={{ marginTop: '60px' }}>
            <NPS />
            <Notification announcements={announcements} />
            {this.navBar()}
            <Settings
              reloadMain={this.loadPage}
            />

            <div>
              <footer style={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: 'center',
                  alignItems: "center",
                  backgroundColor: '#0a003a',
                  position: "fixed",
                  bottom: "0px",
                  height: "50px",
                  width: "100%",
                  zIndex: 100, 
                  color: 'white'
                }}>
                  <p><a href="https://onegiftfoundation.org/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>WishWell was created by The OneGift Foundation</a> | <a href="https://onegiftfoundation.org/the-legal-stuff" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Legal Terms</a></p>
                </footer>
            </div>
          </div>
        )
      }
    }
  }

  navBar() {
    return <nav className='navbar' style={{ backgroundColor: this.state.settings.brandColor }}>
          <img src={this.state.settings.logo[1]} alt={this.state.settings.logo[0]} className='logo'></img>
            <Autocomplete
                title="Name (ID)"
                id="donorName"
                onChange={changedItem => this.setState({ memberSelected: changedItem })}
                // onChange={changedItem => console.log(changedItem)}
                items={this.state.allMembers.map( e => e.fname + ' ' + e.lname + ' (' + e.pnumber + ')')}
                >
                  {({
                    getInputProps,
                    getToggleButtonProps,
                    getRef,
                    inputValue,
                    toggleMenu
                  }) => (
                    <Pane ref={getRef} display="flex" justifyContent="center">
                      {/* View buttons based on user permissions */}
                      {this.state.userPermissions.some(value => ['Super Admin', 'Admin', 'Members'].includes(value)) && <TextInput
                        placeholder="Name or ID Number"
                        value={inputValue}
                        {...getInputProps()}
                      />}
                      {this.state.userPermissions.some(value => ['Super Admin', 'Admin', 'Members'].includes(value)) && <React.Fragment>&nbsp;<Button iconBefore={PersonIcon} isLoading={this.state.memberDetailsLoading} onClick={() => this.changeState("MemberDetails")}>Details</Button>&nbsp;</React.Fragment>}
                      {this.state.userPermissions.some(value => ['Super Admin', 'Admin', 'Members'].includes(value)) && <React.Fragment>&nbsp;<Button iconBefore={AddIcon} disabled={this.state.state === "AddMember"} onClick={() => { this.changeState("AddMember"); this.setState({ member: {} }); } }>Member</Button>&nbsp;</React.Fragment>}
                      <div className='nav-links'>
                      {this.state.userPermissions.some(value => ['Super Admin', 'Admin', 'Orders'].includes(value)) && <React.Fragment>&nbsp;<Button disabled={this.state.state === "OrderForm"} iconBefore={CreditCardIcon} onClick={() => this.changeState("OrderForm")}>Orders</Button>&nbsp;</React.Fragment>}
                      {this.state.userPermissions.some(value => ['Super Admin', 'Admin', 'Generation'].includes(value)) && <React.Fragment>&nbsp;<Popover
                        position={Position.BOTTOM_LEFT}
                        content={
                          <Menu>
                            <Menu.Group>
                              <Menu.Item icon={FormIcon}>
                                <Button appearance="minimal" onClick={() => this.setState({ generateOrderFormsModalShown: true })}>Order Forms and Labels</Button>
                              </Menu.Item>
                              <Menu.Item icon={InboxIcon}>
                                <Button appearance="minimal" onClick={() => this.setState({ generateModalShown: true })}>Cards and Envelopes</Button>
                              </Menu.Item>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <Button iconBefore={CloudDownloadIcon} marginRight={0}>Generate</Button>
                      </Popover>&nbsp;</React.Fragment>}

                      {this.state.userPermissions.some(value => ['Super Admin', 'Admin', 'Reports'].includes(value)) && <React.Fragment>&nbsp;<Button disabled={this.state.state === "Reports"} iconBefore={HorizontalBarChartIcon} onClick={() => this.changeState("Reports")}>Reports</Button>&nbsp;</React.Fragment>}
                      {this.state.userPermissions.some(value => ['Super Admin', 'Admin'].includes(value)) && <React.Fragment>&nbsp;<Button id="settings-button" disabled={this.state.state === "Tools" || this.state.Activity === "Main"} iconBefore={CogIcon} onClick={() => this.changeState("Tools")}>Settings</Button>&nbsp;</React.Fragment>}
                      &nbsp;<Popover
                        position={Position.BOTTOM_LEFT}
                        content={
                          <Menu>
                            <Menu.Group>
                              <Menu.Item icon={FormIcon}>
                                <Tooltip content="Copy referral link to clipboard. We'll donate $100 (in your local currency) for every referral you generate!"><Button appearance="minimal" onClick={() => {
                                    navigator.clipboard.writeText('https://www.wishwell.onegift.ai/?source=referral&referrer=' + this.state.settings.organizationId)
                                    toaster.success('Referral link copied to clipboard. Now share it with your friends via email and social!', {duration: 5,})
                                }}>Referral Link</Button></Tooltip>
                              </Menu.Item>
                              <Menu.Item icon={InboxIcon}>
                                <Button appearance="minimal" onClick={() => window.open('https://senja.io/p/onegift/r/Wg2DDC', '_blank')}>Leave Testimonial</Button>
                              </Menu.Item>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <Button iconBefore={FollowerIcon} marginRight={0}>Share</Button>
                      </Popover>
                      &nbsp;
                      &nbsp;<IconButton icon={HelpIcon} style={{ float: "right" }} onClick={() => window.open('https://www.onegiftfoundation.org/resources', '_blank')}></IconButton>
                      &nbsp;<IconButton icon={LogOutIcon} style={{ float: "right" }} onClick={this.signOut}></IconButton>
                  </div>

                      {/* Mobile friendly navigation */}
                      <div class="mobile">
                        <Popover
                          position={Position.BOTTOM_LEFT}
                          content={
                            <Menu>
                              <Menu.Group>
                              {this.state.userPermissions.some(value => ['Super Admin', 'Orders'].includes(value)) && <React.Fragment><Menu.Item icon={CreditCardIcon}>
                                  &nbsp;<Button appearance="minimal" onClick={() => this.changeState("OrderForm")}>Orders</Button>
                                </Menu.Item></React.Fragment>}
                                {this.state.userPermissions.some(value => ['Super Admin', 'Admin', 'Generation'].includes(value)) && this.state.organization.parentOrgId === '859d0bb4-21c4-4c0d-9804-126093d78552' && <React.Fragment><Menu.Item icon={CloudDownloadIcon}>
                                  &nbsp;<Popover
                                    position={Position.BOTTOM_LEFT}
                                    content={
                                      <Menu>
                                        <Menu.Group>
                                          <Menu.Item icon={FormIcon}>
                                            <Button appearance="minimal" onClick={() => this.setState({ generateOrderFormsModalShown: true })}>Order Forms and Labels</Button>
                                          </Menu.Item>
                                          <Menu.Item icon={InboxIcon}>
                                            <Button appearance="minimal" onClick={() => this.setState({ generateModalShown: true })}>Cards and Envelopes</Button>
                                          </Menu.Item>
                                        </Menu.Group>
                                      </Menu>
                                    }
                                  >
                                    <Button appearance="minimal" marginRight={0}>Generate</Button>
                                  </Popover>
                                </Menu.Item></React.Fragment>}
                                {this.state.userPermissions.some(value => ['Super Admin', 'Admin', 'Reports'].includes(value)) && <React.Fragment><Menu.Item icon={HorizontalBarChartIcon}>
                                  &nbsp;<Button appearance="minimal" onClick={() => this.changeState("Reports")}>Reports</Button>
                                </Menu.Item></React.Fragment>}
                                {this.state.userPermissions.some(value => ['Super Admin', 'Admin'].includes(value)) && <React.Fragment><Menu.Item icon={CogIcon}>
                                  &nbsp;<Button appearance="minimal" onClick={() => this.changeState("Tools")}>Settings</Button>
                                </Menu.Item></React.Fragment>}
                                <Menu.Item icon={HelpIcon}>
                                  <Button appearance="minimal" onClick={() => window.open('https://www.onegiftfoundation.org/resources', '_blank')}>Help</Button>
                                </Menu.Item>
                                <Menu.Item icon={FollowerIcon}>
                                  &nbsp;<Popover
                                    position={Position.BOTTOM_LEFT}
                                    content={
                                      <Menu>
                                        <Menu.Group>
                                          <Menu.Item icon={FormIcon}>
                                            <Tooltip content="Copy referral link to clipboard. We'll donate $100 (in your local currency) for every referral you generate!"><Button appearance="minimal" onClick={() => {
                                                navigator.clipboard.writeText('https://www.wishwell.onegift.ai/?source=referral&referrer=' + this.state.settings.organizationId)
                                                toaster.success('Referral link copied to clipboard. Now share it with your friends via email and social!', {duration: 5,})
                                            }}>Referral Link</Button></Tooltip>
                                          </Menu.Item>
                                          <Menu.Item icon={InboxIcon}>
                                            <Button appearance="minimal" onClick={() => window.open('https://senja.io/p/onegift/r/Wg2DDC', '_blank')}>Leave Testimonial</Button>
                                          </Menu.Item>
                                        </Menu.Group>
                                      </Menu>
                                    }
                                  >
                                    <Button appearance="minimal" marginRight={0}>Share</Button>
                                  </Popover>
                                </Menu.Item>
                                <Menu.Item icon={LogOutIcon}>
                                  <Button appearance="minimal" onClick={this.signOut}>Sign-out</Button>
                                </Menu.Item>
                              </Menu.Group>
                            </Menu>
                          }
                        >
                          <IconButton icon={AlignJustifyIcon} marginRight={0} />
                        </Popover>
                      </div>  
                    </Pane>
                  )}
              </Autocomplete>
              <Pane>
                  <Dialog
                    isShown={this.state.generateModalShown}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    title="Please select month and year..."
                    isConfirmLoading={this.state.generateLoading}
                    onConfirm={() => { this.setState({ generateLoading: true }); this.GenerateCandE(); } }
                    confirmLabel={this.state.generateLoading ? 'Generating...' : this.state.emailCards === false ? 'Generate Cards and Envelopes' : 'Generate and E-mail Cards'}
                    onCloseComplete={() => this.setState({ generateModalShown: false, generateLoading: false })}
                  >
                  <table style={{borderSpacing: '10px'}}><tbody>
                    <tr><td><Select name="month" id="month">
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </Select></td>
                    <td><Select name="year" id="year">
                      <option value={this.state.year}>{this.state.year}</option>
                      <option value={this.state.year + 1}>{this.state.year + 1}</option>
                    </Select></td>
                    <td><Select name="cAndEType" id="cAndEType" onChange={(e) => this.handleChange(e)}>
                      <option value="ManyFiles">One file with all cards</option>
                      <option value="OneFile">One file per card</option>
                    </Select></td>
                    <td><Checkbox type="checkbox" id="emailCards" name="emailCards" checked={this.state.emailCards} onChange={this.handleChange}></Checkbox></td>
                    <td><label htmlFor="emailCards">E-mail cards</label></td></tr>
                  </tbody></table>
                  </Dialog>
              </Pane>

              <Pane>
                  <Dialog
                    isShown={this.state.generateOrderFormsModalShown}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    title="Please select month and year..."
                    isConfirmLoading={this.state.generateOrderFormsLoading}
                    onConfirm={() => { 
                      if (this.state.emailOrderForms) {
                        if (window.confirm("Please confirm that you would like to email all of your members with the Email Order Forms box checked in their member details page. \n\nWe strongly suggest notifying your members before sending this email to ensure that it is not ignored, missed, or marked as spam. \n\nYou can head over to onegiftfoundation.org/resources/emails to learn more and get a draft email to send to your members.")) {
                          this.setState({ generateOrderFormsLoading: true }); this.GenerateOrderForms();
                        }
                      }
                      else {
                        this.setState({ generateOrderFormsLoading: true }); this.GenerateOrderForms();
                      }
                    } }
                    confirmLabel={this.state.generateOrderFormsLoading ? 'Generating...' : 'Generate Mailing Labels and Order Forms'}
                    onCloseComplete={() => this.setState({ generateOrderFormsModalShown: false, generateOrderFormsLoading: false })}
                  >
                    <table style={{borderSpacing: '10px'}}><tbody>
                      <tr>
                        <td><Select name="order-quarter" id="order-quarter">
                          <option value="Q1">Q1</option>
                          <option value="Q2">Q2</option>
                          <option value="Q3">Q3</option>
                          <option value="Q4">Q4</option>
                        </Select></td>
                        &nbsp;
                        <td><Select name="order-labels" id="order-labels">
                          <option value="coversheet">Individual Coversheets</option>
                          <option value="stickers">Label stickers and coversheet</option>
                        </Select></td>
                        &nbsp;
                        <br></br>
                        <td><Checkbox type="checkbox" id="emailEForms" name="emailEForms" checked={this.state.emailOrderForms} onChange={this.handleChange}></Checkbox></td>
                        <td><label htmlFor="emailEForms">E-mail order forms</label></td>
                      </tr>
                      <br></br>
                    </tbody></table>
                    <a href="https://drive.google.com/file/d/11g-4Z8DUOVitVQyXsUXAOfDqowD2TvmV/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>Click me to download the cover sheet for the label stickers.</a>
                  </Dialog>
              </Pane>
      </nav>
  }
}

export default Main