import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

import { API, graphqlOperation, Auth } from 'aws-amplify'
import { updateUser } from '../graphql/mutations'
import { userByUserName } from '../graphql/queries'

// UI
import { Button, toaster } from 'evergreen-ui'

// Analytics
import Bugsnag from '@bugsnag/js';
import mixpanel from 'mixpanel-browser';

class NPS extends Component {
  constructor(props) {
    super(props);

    const { cookies } = props;
    const lastNpsSurvey = cookies.get('lastNpsSurvey');

    this.state = {
      score: null,
      submitted: false,
      lastNpsSurvey,
      showSurvey: false,
    };
  }
  
  componentDidMount = async () => {
    const showSurvey = await this.showSurvey()
    this.setState({ showSurvey })
  }

  showSurvey = async () => {

    const user_info = await Auth.currentUserInfo()
    const userName = user_info.username
    const user = await API.graphql(graphqlOperation(userByUserName, { userName: userName }))
    const userRecord = user.data.userByUserName.items[0]

    const lastNpsSurvey = userRecord.NPS[0].match(/date=(.*?),/)[1];
    const score = userRecord.NPS[0].match(/score=(\w+)/)[1];

    const surveyFrequency = 180 * 24 * 60 * 60 * 1000; // 180 days in milliseconds
    const firstSurveyDelay = 60 * 24 * 60 * 60 * 1000; // 60 days in milliseconds

        // If no score reported, check if it's time to show the survey for first time, else check if it's time to show the survey again
    const currentTime = new Date().getTime();
    const lastSurveyTime = new Date(lastNpsSurvey).getTime();
    if (score === 'none') {
      return currentTime - lastSurveyTime > firstSurveyDelay;
    }
    else {
      return currentTime - lastSurveyTime > surveyFrequency;
    }
  }

  setScore = async (score) => {
    await this.setState({ score });
    // this.state.score = score
    await this.handleSubmit();
  }

  handleSubmit = async () => {
    const { score } = this.state;
    const { cookies } = this.props;

    if (score !== null) {
        // Send the score to your server, API, or analytics service.

        try {
            // Get user and update NPS array
            const user_info = await Auth.currentUserInfo()
            const username = user_info.username
            const user = await API.graphql(graphqlOperation(userByUserName, { userName: username }))
            const userRecord = user.data.userByUserName.items[0]
            var updatedUser = {}
            updatedUser.id = userRecord.id
            const date = new Date().toISOString().split('T')[0]
            if (typeof updatedUser.NPS === 'undefined' || !Array.isArray(updatedUser.NPS)) {
                updatedUser.NPS = [];
            }
            updatedUser.NPS.unshift({
                date: date,
                score: score
            })
            API.graphql(graphqlOperation(updateUser, {input: updatedUser}))
            mixpanel.people.set({
              'NPS Score': score,
              'NPS Date': new Date().toISOString(),
            });
            if (score > 8) {
              mixpanel.track('Customer evangelized!');
              mixpanel.people.set({
                'Detractor': false,
                'Evangelist': true,
                'Evangelist Date': new Date().toISOString(),
              })
            }
            if (score < 7) {
              mixpanel.track('Customer detracted!');
              mixpanel.people.set({
                'Detractor': true,
                'Evangelist': false,
                'Detractor Date': new Date().toISOString(),
              })
            }

            mixpanel.track('NPS Score Logged', {
                'date': date,
                'score': score,
            });

            toaster.success("Thank you for your feedback!")

            // Email new NPS score

            const options = {
              from: '"WishWell by OneGift" <experience@onegiftfoundation.org>',
              to: ['ryan@onegift.ai'],
              subject: 'New NPS score',
              html: `<p>User: ${userRecord.userName}</p><p>Score: ${score}</p>`,
            }
            // Send email call to api
            const apiName = 'wishwellapi';
            var path = '/wishwellapi/sendEmail';
            var myInit = { // OPTIONAL
                body: {
                  options: options
                },
                headers: {}, // OPTIONAL
            };
            await API.post(apiName, path, myInit)
            
            this.setState({ submitted: true });
            cookies.set('lastNpsSurvey', new Date().toISOString(), { path: '/' });
        } catch (error) {
            Bugsnag.notify(error);
            console.log(error)
        }
        
    }
  }

  render() {
    const { score, submitted } = this.state;

    if (!this.state.showSurvey) {
      return null;
    }

    if (submitted) {
      return null;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center', margin: 'auto', paddingTop: '5px', paddingBottom: '5px' }}>
        <table><tbody>
          <tr><div className="nps-question"><td>How likely are you to recommend this app to a friend or colleague?</td>
              <td>&nbsp;{Array.from({ length: 11 }, (_, i) => (
              <Button
                key={i}
                className={`nps-button ${score === i ? 'selected' : ''}`}
                onClick={() => this.setScore(i)}
              >
                {i}
              </Button>
            ))}</td>
          </div></tr>
        </tbody></table>
      </div>
    );
  }
}

export default withCookies(NPS);