/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      pnumber
      mnumber
      ptype
      pactive
      eforms
      pforms
      ecards
      pcards
      eReminders
      birthdayBooster
      fname
      lname
      email
      phone
      address1
      address2
      DOB
      lifeCycle
      anumber
      apayment
      notes
      searchField
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pnumber
        mnumber
        ptype
        pactive
        eforms
        pforms
        ecards
        pcards
        eReminders
        birthdayBooster
        fname
        lname
        email
        phone
        address1
        address2
        DOB
        lifeCycle
        anumber
        apayment
        notes
        searchField
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      donorPNumber
      donorName
      receiverPNumber
      year
      paymentType
      checkNumber
      amount
      checkDate
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        donorPNumber
        donorName
        receiverPNumber
        year
        paymentType
        checkNumber
        amount
        checkDate
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      chapterName
      orgEmail
      minWishPrice
      events
      customFields
      makeAWishURL
      defaultWishers
      envelopeReturnName
      envelopeReturnAddressLine1
      envelopeReturnAddressLine2
      logo
      brandColor
      taxNumber
      orgType
      giveLocal
      paymentType
      paymentId
      country
      currency
      bankInfo
      stripeAccountId
      stripeOnboardingComplete
      referrer
      referralCredits
      fullAuto
      birthdayBooster
      fullAutoEnabledDate
      charityPartner
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chapterName
        orgEmail
        minWishPrice
        events
        customFields
        makeAWishURL
        defaultWishers
        envelopeReturnName
        envelopeReturnAddressLine1
        envelopeReturnAddressLine2
        logo
        brandColor
        taxNumber
        orgType
        giveLocal
        paymentType
        paymentId
        country
        currency
        bankInfo
        stripeAccountId
        stripeOnboardingComplete
        referrer
        referralCredits
        fullAuto
        birthdayBooster
        fullAutoEnabledDate
        charityPartner
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userName
      NPS
      lifeCycle
      permissions
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        NPS
        lifeCycle
        permissions
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      active
      parentOrgId
      independent
      source
      referrer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        active
        parentOrgId
        independent
        source
        referrer
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      transactionDate
      quarter
      year
      wishType
      donorPNumber
      donorFName
      donorLName
      donorEmail
      donorAddress
      donorPhone
      receiverPNumbers
      localCurrency
      localAmount
      amount
      transactionNumber
      paymentType
      organizationId
      organizationName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        transactionDate
        quarter
        year
        wishType
        donorPNumber
        donorFName
        donorLName
        donorEmail
        donorAddress
        donorPhone
        receiverPNumbers
        localCurrency
        localAmount
        amount
        transactionNumber
        paymentType
        organizationId
        organizationName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      organizations
      enabledOrganizations
      activeOrganizations
      orgChurn
      users
      verifiedUsers
      members
      activeMembers
      memberChurn
      wishes
      donations
      ccDonations
      numDonations
      feesGenerated
      customerSources {
        source
        count
        __typename
      }
      donorSources {
        source
        count
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizations
        enabledOrganizations
        activeOrganizations
        orgChurn
        users
        verifiedUsers
        members
        activeMembers
        memberChurn
        wishes
        donations
        ccDonations
        numDonations
        feesGenerated
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const membersByOrganizationId = /* GraphQL */ `
  query MembersByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pnumber
        mnumber
        ptype
        pactive
        eforms
        pforms
        ecards
        pcards
        eReminders
        birthdayBooster
        fname
        lname
        email
        phone
        address1
        address2
        DOB
        lifeCycle
        anumber
        apayment
        notes
        searchField
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ordersByDonorPNumber = /* GraphQL */ `
  query OrdersByDonorPNumber(
    $donorPNumber: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByDonorPNumber(
      donorPNumber: $donorPNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        donorPNumber
        donorName
        receiverPNumber
        year
        paymentType
        checkNumber
        amount
        checkDate
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ordersByOrganizationId = /* GraphQL */ `
  query OrdersByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        donorPNumber
        donorName
        receiverPNumber
        year
        paymentType
        checkNumber
        amount
        checkDate
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const settingsByMakeAWishURL = /* GraphQL */ `
  query SettingsByMakeAWishURL(
    $makeAWishURL: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    settingsByMakeAWishURL(
      makeAWishURL: $makeAWishURL
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chapterName
        orgEmail
        minWishPrice
        events
        customFields
        makeAWishURL
        defaultWishers
        envelopeReturnName
        envelopeReturnAddressLine1
        envelopeReturnAddressLine2
        logo
        brandColor
        taxNumber
        orgType
        giveLocal
        paymentType
        paymentId
        country
        currency
        bankInfo
        stripeAccountId
        stripeOnboardingComplete
        referrer
        referralCredits
        fullAuto
        birthdayBooster
        fullAutoEnabledDate
        charityPartner
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const settingsByOrganizationId = /* GraphQL */ `
  query SettingsByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    settingsByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chapterName
        orgEmail
        minWishPrice
        events
        customFields
        makeAWishURL
        defaultWishers
        envelopeReturnName
        envelopeReturnAddressLine1
        envelopeReturnAddressLine2
        logo
        brandColor
        taxNumber
        orgType
        giveLocal
        paymentType
        paymentId
        country
        currency
        bankInfo
        stripeAccountId
        stripeOnboardingComplete
        referrer
        referralCredits
        fullAuto
        birthdayBooster
        fullAutoEnabledDate
        charityPartner
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByUserName = /* GraphQL */ `
  query UserByUserName(
    $userName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUserName(
      userName: $userName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        NPS
        lifeCycle
        permissions
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationById = /* GraphQL */ `
  query OrganizationById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        active
        parentOrgId
        independent
        source
        referrer
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const transactionsByOrganizationId = /* GraphQL */ `
  query TransactionsByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionDate
        quarter
        year
        wishType
        donorPNumber
        donorFName
        donorLName
        donorEmail
        donorAddress
        donorPhone
        receiverPNumbers
        localCurrency
        localAmount
        amount
        transactionNumber
        paymentType
        organizationId
        organizationName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
